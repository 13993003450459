<div class="p-relative overflow-h">
  <div #fistStep class="firstStep">
    <h2 mat-dialog-title class="ke-green">Autenticazione richiesta</h2>
    <mat-dialog-content class="mat-typography ke-grey">
      <p>Il mittente richiede si verificare la propria identità tramite:</p>
      <li class="bold mt-2h">SMS</li>
    </mat-dialog-content>
    <div class="fill"></div>
    <div class="mt-2h d-flex">
      <button mat-button mat-dialog-close class="mr-2">Annulla</button>
      <button mat-button cdkFocusInitial color="primary" (click)="getOTP()">Richiedi codice via SMS</button>
      <div class="fill d-flex justify-content-center">
        <mat-spinner *ngIf="loading" class="green" diameter="25"></mat-spinner>
      </div>
      <!-- <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button> -->
    </div>
  </div>
  
  <div #secondStep class="secondStep">
    <mat-dialog-content class="mat-typography ke-grey">
      <div class="stepper">
          <div class="step" [class.fadeLeftOut]="status === 'error'">
              <h2 mat-dialog-title class="ke-green">Verifica codice</h2>
          </div>
          <div class="step out" [class.fadeRightIn]="status === 'error'">
              <h2 mat-dialog-title class="ke-red">Codice errato</h2>
          </div>
      </div>

      <div class="mt-2h">Un codice di 6 cifre è stato inviato al numero di cellulare <b>{{phone}}</b></div>
      <div class="fs-1r">Riferimento operazione: <b>{{operationID}}</b></div>

      <div class="mt-2h" [ngClass]="{'ke-green': (status==='success'), 'ke-red': (status==='error')}">
          <mat-form-field appearance="outline" [hintLabel]="'Tempo '+(counter.date < 1? 'scaduto.': 'rimasto:')">
              <mat-label class="c-inherit">Codice OTP</mat-label>
              <input id="otpInput"
                      #otpInput="ngModel"
                      matInput
                      max="6"
                      [disabled]="otpDisabled"
                      type="number"
                      pattern="\d{6}"
                      placeholder="inviato via sms" 
                      [(ngModel)]="otp"
                      (keypress)="inputFormat()"
                      autocomplete="off">
              <mat-hint *ngIf="counter.date > 0" align="end">{{counter.m +':'+ counter.s}}</mat-hint>
              <!-- <mat-error *ngIf="otpInput.hasError('pattern')">Codice di 6 cifre</mat-error> -->
          </mat-form-field>
      </div>
    </mat-dialog-content>

      <div class="f-center">
        <button mat-button mat-dialog-close class="mr-2">Annulla</button>
          <button mat-button 
                  [disabled]="!otp || ((otp+'').length < 6)"
                  [color]="status === 'error' ? 'warn' : 'primary'"
                  (click)="autoAuth()">{{status === 'error' ? 'Riprova' : 'Autenticati'}}</button>
          <div class="fill d-flex pl-2r">
              <mat-spinner class="green" *ngIf="loading" diameter="25"></mat-spinner>
          </div>
      </div>
  </div>

  <div #thirdStep class="thirdStep">
    <h2 mat-dialog-title class="ke-red">{{errorMessage}}</h2>
    <mat-dialog-content class="ke-grey">
        <p class="mt-2h">Il codice OTP per questa transazione è scaduto.</p>
        <p>Per continuare, richiedi un nuovo codice.</p>
    </mat-dialog-content>
    <div class="py-2h mt-2h button-load-r">
      <button mat-button mat-dialog-close class="mr-2">Annulla</button>
        <button mat-raised-button 
                color="warn"
                class="bump-button"
                (click)="getOTP()"
                [disabled]="!otpDisabled">Richiedi il codice via SMS</button>
        <div class="fill d-flex justify-content-center">
            <mat-spinner class="red" *ngIf="loading" diameter="25"></mat-spinner>
        </div>
    </div>
</div>
</div>