import { Pipe, PipeTransform } from '@angular/core';
import { Signature } from '../classes/document';

@Pipe({
  name: 'pagePipe',
  pure: false
})
export class PagePipe implements PipeTransform {

  transform(items: Signature[], page: number, docId: number) {
    if (!items || !page) {
      return items;
    }
    return items.filter(el => el.page === page && el.documentID === docId);
  }

}
