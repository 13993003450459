import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* ANGULAR MATERIALS */
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WarnMessageComponent } from './components/warn-message/warn-message.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './components/loader/loader.component';
import { AuthOtpDialogComponent } from './components/dialog/auth-otp-dialog/auth-otp-dialog.component';
import { KeWaveComponent } from './components/ke-wave/ke-wave.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { MatSliderModule } from '@angular/material/slider';
import { KefirmaButtonFabComponent } from './components/kefirma-button-fab/kefirma-button-fab.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { OpenSidebarOnSwipeDirective } from './directives/open-sidebar-onswipe.directive';
import { KeLightCornerComponent } from './components/ke-light-corner/ke-light-corner.component';
import { NoteDialogComponent } from './components/dialog/note-dialog/note-dialog.component';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddSignatureDialogComponent } from './components/dialog/add-signature-dialog/add-signature-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ConfirmSheetsComponent } from './components/sheets/confirm-sheets/confirm-sheets.component';
import { MatBadgeModule } from '@angular/material/badge';
import { DetailSheetComponent } from './components/sheets/detail-sheet/detail-sheet.component';
import { ClearOperationComponent } from './components/dialog/clear-operation/clear-operation.component';
import { MatRadioModule } from '@angular/material/radio';
import { DeprecatedComponent } from './components/deprecated/deprecated.component';
import { PagePipe } from './pipes/page.pipe';
import { AuthOtpDigitalDialogComponent } from './components/dialog/auth-otp-digital-dialog/auth-otp-digital-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    WarnMessageComponent,
    LoaderComponent,
    AuthOtpDialogComponent,
    KeWaveComponent,
    DebounceClickDirective,
    KefirmaButtonFabComponent,
    ConfirmDialogComponent,
    OpenSidebarOnSwipeDirective,
    KeLightCornerComponent,
    NoteDialogComponent,
    AddSignatureDialogComponent,
    ConfirmSheetsComponent,
    DetailSheetComponent,
    ClearOperationComponent,
    DeprecatedComponent,
    PagePipe,
    AuthOtpDigitalDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatSliderModule,
    MatProgressBarModule,
    PinchZoomModule,
    FormsModule,
    MatChipsModule,
    DragDropModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatRadioModule,
    MatSnackBarModule
  ],
  entryComponents: [
    AuthOtpDialogComponent,
    ConfirmDialogComponent,
    NoteDialogComponent,
    AddSignatureDialogComponent,
    ConfirmSheetsComponent,
    DetailSheetComponent,
    ClearOperationComponent
  ],
  exports: [
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    WarnMessageComponent,
    MatProgressSpinnerModule,
    LoaderComponent,
    AuthOtpDialogComponent,
    MatButtonModule,
    KeWaveComponent,
    MatFormFieldModule,
    MatInputModule,
    DebounceClickDirective,
    MatSliderModule,
    KefirmaButtonFabComponent,
    MatProgressBarModule,
    ConfirmDialogComponent,
    PinchZoomModule,
    OpenSidebarOnSwipeDirective,
    KeLightCornerComponent,
    NoteDialogComponent,
    MatChipsModule,
    DragDropModule,
    AddSignatureDialogComponent,
    MatTooltipModule,
    MatCheckboxModule,
    ConfirmSheetsComponent,
    MatBottomSheetModule,
    DetailSheetComponent,
    ClearOperationComponent,
    MatBadgeModule,
    MatRadioModule,
    PagePipe,
    DeprecatedComponent,
    MatSnackBarModule
  ]
})
export class SharedModule { }
