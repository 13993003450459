import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { RejectDigitalSignatureRequest } from 'src/app/shared/classes/digital';
import { RefuseTransactionRequest } from 'src/app/shared/classes/transaction-pages';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

interface DialogData {
  title: string;
  body: string;
  isDigital: boolean;
}

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})

// il nome è fuorviante.
// non è esattamente un dialog dinamico, ma bensì dedicato al ws RefuseTransaction.
export class NoteDialogComponent implements OnInit {

  loading: boolean;
  value: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: DialogData,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private api: ApiHttpService,
    private router: Router
  ) { }

  get data(): DialogData { return this._data; }
  ngOnInit(): void {}
  next(): void {
    if(!this.loading){
      this.loading = true;
      let obs = this.data.isDigital? this.api.rejectDigitalSignature$(new RejectDigitalSignatureRequest(this.value)) : this.api.refuseTransaction$(RefuseTransactionRequest.make(this.value));
      obs.subscribe(
        data => {
          if (data) {
            this.dialogRef.close(this.value);
            this.router.navigate(['report', 'rejected']);
          }
        },
        err => {this.dialogRef.close(this.value);},
        () => this.loading=false
      );
    }
  }

}
