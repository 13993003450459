import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ke-light-corner',
  templateUrl: './ke-light-corner.component.html',
  styleUrls: ['./ke-light-corner.component.scss']
})
export class KeLightCornerComponent implements OnInit {

  @Input() disabled: boolean;
  @Output() sign: EventEmitter<boolean>;
  constructor() { 
    this.sign = new EventEmitter();
  }

  ngOnInit(): void {}
  emit(): void {this.sign.emit(true);}

}
