import { Injectable } from '@angular/core';
import { StartDigitalSignatureResponse } from '../classes/digital';
import { TransactionPagesDocument } from '../classes/transaction-pages';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private _timestamp: number;
  private _mY: number;
  private _mX: number;

  constructor() {
    this._timestamp = 0;
  }

  moveSpeed(event, speed: number = 3000): boolean {
    // V = S/T
      const now = Date.now();
      const dt = now - this._timestamp;
      const distanceY = Math.abs(event.screenY - this._mY);
      const distanceX = Math.abs(event.screenX - this._mX);
      const speedY = Math.round(distanceY / dt * 1000); // ps/s
      const speedX = Math.round(distanceX / dt * 1000);

      this._mY = event.screenY;
      this._mX = event.screenX;
      this._timestamp = now

      return (speedX >= speed || speedY >= speed)
      // if ((distanceX >= 70 || distanceY >= 70) ) { this.hideSidemenu = false; }
  }

  digitalConverter(data: StartDigitalSignatureResponse): TransactionPagesDocument {
    let pagesBase64: string[] = [];
    for (let i = 0; i < data.pageImages.length; i++) pagesBase64.push(data.pageImages[i].imageBase64);
    return {
      documentID: 0,
      documentCounter: 0,
      fileName: data.fileName,
      pages: data.pages,
      signatureFields: [data.signatureField],
      pagesBase64
    }
  }

  zoom(zoomScale: number, el: HTMLElement): void {
    if (el) {
      this.setZoom(zoomScale, el);
    }
  }

  private setZoom(zoom, el): void {
    let transformOrigin = [0, 0];
    let p = ["webkit", "moz", "ms", "o"];
    let s = "scale(" + zoom + ")";
    let oString = (transformOrigin[0] * 100) + "% " + (transformOrigin[1] * 100) + "%";

    for (let i = 0; i < p.length; i++) {
        el.style[p[i] + "Transform"] = s;
        el.style[p[i] + "TransformOrigin"] = oString;
    }

    el.style["transform"] = s;
    el.style["transformOrigin"] = oString;
  }
}
