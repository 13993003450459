<mat-sidenav-container class="h-100">
    <!-- LEFT CONTENT -->
    <mat-sidenav #sidenav
                 id="sidenavContainer"
                 class="bg-kefirma-dark h-100 d-flex flex-column"
                 [mode]="laptopQuery.matches ? 'side' : 'over'"
                 opened>
            <header class="p-1r" (click)="!laptopQuery.matches && sidenav.close()">
                <img src="assets/img/kefirma_logo_inline.png">
            </header>
            <main id="thumbnailContainer" class="fill">
                <section [id]="'doc'+(di+1)" *ngFor="let doc of documents; index as di; first as f">
                    <div class="f-center justify-content-between px-3">
                        <h3 [class.mt-0]="f && hideProgressBar">{{doc.fileName}}</h3>
                        <mat-spinner *ngIf="!hideProgressBar" diameter="25" class="green"></mat-spinner>
                    </div>
                    <nav class="p-3">
                        <figure class="m-0" [class.page-focus]="toolbarInfo.page === i+1 && doc.documentID === toolbarInfo.document.documentID" *ngFor="let page of documents[di].pagesBase64; index as i">
                            <app-ke-light-corner [disabled]="!isSignPage(i, di)">
                                <img (click)="thumbnailSelect(i+1, di+1)"
                                    [id]="'thumbnailDoc'+(di+1)+'page'+(i+1)"
                                    [src]="'data:image/png;base64,'+ page">
                            </app-ke-light-corner>
                            
                        </figure>
                    </nav>
                </section>
                <div class="h-100 f-center" *ngIf="!documents.length"><mat-spinner class="purple"></mat-spinner></div>
                <!-- <mat-slider class="w-100" [min]="50" [max]="200" [(ngModel)]="zoom" (change)="dai2()"></mat-slider> -->
            </main>
            <footer>
                <button mat-raised-button color="warn" class="w-100" (click)="openNoteDialog()" [disabled]="!pageLoaded">RIFIUTA</button>
                <!-- <button mat-raised-button color="primary" class="w-100" (click)="complete()" [disabled]="hasRequiredSignPage()">CONFERMA</button> -->
            </footer>
    </mat-sidenav>

    <!-- RIGHT CONTENT -->
    <mat-sidenav-content id="rightContent" class="bg-kefirma p-static" (mousemove)="movetHandle($event)" (scroll)="scrollBack($event)" (swiperight)="sidenav.open()">
        <!-- TOOLBAR -->
        <header
            [class.hide-toolbar]="!showSidemenu"
            [class.fix-width-sidemenu]="laptopQuery.matches"
            (mouseenter)="clearsidemenuTimeout()"
            (mouseleave)="showSidemenu = true">
            <section>
                <div (click)="sidenav.toggle()" *ngIf="!laptopQuery.matches">
                    <app-kefirma-button-fab></app-kefirma-button-fab>
                </div>
                <h3 (click)="!laptopQuery.matches && sidenav.toggle()" class="m-0">{{toolbarInfo && toolbarInfo.document && toolbarInfo.document.fileName}}</h3>
            </section>
            <form *ngIf="!mobileQuery.matches">
                <input class="page-count-input"
                    name="page-count-input"
                    type="number"
                    min="1"
                    [max]="toolbarInfo.document && toolbarInfo.document.pages"
                    [value]="toolbarInfo.page"
                    (change)="toolbarPageCounterChange($event, toolbarInfo.document.documentCounter)">
                <span> / {{toolbarInfo.document && toolbarInfo.document.pages}}</span>
            </form>
            <mat-progress-bar *ngIf="!hideProgressBar" mode="indeterminate"></mat-progress-bar>
        </header> 
        <pinch-zoom overflow="visible" [wheel]="false" backgroundColor="none" disableZoomControl="disable">
            <main id="documentsContainer" 
                (click)="showSidemenu = true"
                class="w-100 h-100 f-center justify-content-start flex-column">
                <article [id]="'doc'+(di+1)" *ngFor="let doc of documents; index as di" class="f-center flex-column">
                    <figure *ngFor="let page of documents[di].pagesBase64; index as i; first as fp;"
                    [class.f-center]="zoom < 1"
                    [class.mt-3]="fp">
                        <img [id]="'doc'+(di+1)+'page'+(i+1)"
                            [src]="'data:image/png;base64,'+ page"
                            [style.max-width.%]="zoom*100"
                            [class.full-height]="fullHeight">
                        <aside *ngIf="isSignPage(i, di)" [style.width.%]="fullHeight? 100: zoom*100" class="h-100">
                            <span *ngFor="let sf of getSignPageField(i, di)"
                                [id]="'sf' + sf.signatureFieldID"
                                (click)="sign()"
                                class="sign-field f-center"
                                [class.required]="sf.required"
                                [class.d-none]="sf.signed"
                                [style.width.%]="sf.w"
                                [style.height.%]="sf.h"
                                [style.left.%]="sf.y"
                                [style.bottom.%]="sf.x">
                                Clicca per firmare
                            </span>
                        </aside>
                    </figure>
                </article>
            </main>
        </pinch-zoom> 
        <!-- <mat-chip-list *ngIf="mobileQuery.matches" class="chip-page">
            <mat-chip color="primary">1 / 3</mat-chip>
        </mat-chip-list>      -->
        <div class="info-clip" [class.active]="showInfo" >
            <div class="inner" (mouseenter)="mouseEnterInfo()" (mouseleave)="showSidemenu = true">
                <span>i</span>
                <h3>Firma la pratica</h3>
                <p>leggi il documento e clicca sull'area evidenziate per firmare e confermare.</p>
                <button mat-raised-button (click)="toNextSignField()">vai al punto firma</button>
                <!-- <img src="assets/img/toon-hand.png"> -->
            </div>
        </div>
        <aside class="nav-page-sidemenu block-sidenav" (mouseenter)="clearsidemenuTimeout()" (mouseleave)="showSidemenu = true">
            <button mat-mini-fab color="warn" class="mb-2 block2" [class.hide]="!showSidemenu" (click)="navPages(-1)"><mat-icon>expand_less</mat-icon></button>
            <button mat-mini-fab color="warn" class="block2" [class.hide]="!showSidemenu" (click)="navPages(1)"><mat-icon>expand_more</mat-icon></button>
        </aside>
        <aside class="block-sidenav" (mouseenter)="clearsidemenuTimeout()" (mouseleave)="showSidemenu = true">
            <button mat-mini-fab color="warn" 
                    class="mb-4 block1"
                    [class.hide]="!showSidemenu"                    
                    (click)="heightWidthToggle()">
                <mat-icon>{{fullHeight? 'fullscreen': 'fullscreen_exit'}}</mat-icon>
            </button>
            <button mat-mini-fab color="warn" class="mb-2 block1" [class.hide]="!showSidemenu" (click)="setZoom(zoom + zoomCounter)"><mat-icon>add</mat-icon></button>
            <button mat-mini-fab color="warn" class="block1" [class.hide]="!showSidemenu" (click)="setZoom(zoom - zoomCounter)"><mat-icon>remove</mat-icon></button>
        </aside>
        <!-- <img src="../../assets/img/kedos-logo.png" class="bg-kedos"> -->
    </mat-sidenav-content>
</mat-sidenav-container>
