import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthComponent } from 'src/app/auth/auth.component';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { SignDocumentRequest, SignOperationRequest } from 'src/app/shared/classes/sign';

interface DialogData {
  signatureFieldID: number;
  gpsInfo: string;
}

@Component({
  selector: 'app-auth-otp-dialog',
  templateUrl: './auth-otp-dialog.component.html',
  styleUrls: ['./auth-otp-dialog.component.scss']
})
export class AuthOtpDialogComponent extends AuthComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: DialogData,
    private dialog: MatDialogRef<AuthOtpDialogComponent>,
    private a: ApiHttpService,
    private r: Router
  ){ super(a, r); }

  getOTP() {
    if (this.transactionID && !this.loading) {
      this.loading = true;
      this.a.startSignOperation$(SignOperationRequest.make(this._data.signatureFieldID)).subscribe(
        data =>{// TODO switch -1 0
          this.operationID = data.operationID;
          this.counter.date = new Date().getTime() + 300000; //300000 = 5m
          this.cd = setInterval(() => {
            const now = new Date().getTime();
            const timeLeft = this.counter.date - now;
            let m: string | number = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            let s: string | number = Math.floor((timeLeft % (1000 * 60)) / 1000);
            m = m < 10? `0${m}`: `${m}`; s = s < 10? `0${s}`: `${s}`
            this.counter = {date: this.counter.date, m, s}
            if (timeLeft < 0) {
              clearInterval(this.cd); this.counter = {date: 0, m: '', s: ''}; 
              this.errorMessage = 'Codice scaduto';
              this.next('error');
            }
          }, 1000);
          const otpInput = document.getElementById('otpInput');
          // QUESTO METODO E' USATO ANCHE PER 'RICHIEDERE UNA NUOVA OTP'
          // reiposta lo status a success e muove il div a destra.
          if (this.status === 'error') {
            this.otp = null;
            this.back();
          } else { this.next('success'); }
          setTimeout(() => otpInput.focus(), 500); // "Simpatico"
          this.loading = false;
        },
        err => this.loading = false, // TODO qualcosa
      );
    }
  }

  autoAuth(): void {
    this.a.signDocument$(SignDocumentRequest.make(this._data.signatureFieldID, this.operationID, this.otp, this._data.gpsInfo)).subscribe(
      data => {
        switch(data.status){
          case 0: // CODICE VALIDO 
          this.dialog.close(true);
          break;
          case -2: // CODICE SCADUTO
          this.next('error');
          break;
          case -4: // CODICE NON VALIDO
          this.status = 'error'
          break;
          default: break;
        }
      }
    );
  }

  next(status: 'success' | 'error' ): void {
    const moveLeft = 'translate(-100%, 0%)';
    const moveFocus = 'translate(0%, 0%)';
    this.status = status;
    if (status === 'success') {
      this.otpDisabled = false;
      this.fsSection.style.opacity = '0';
      this.fsSection.style.transform = moveLeft;
      this.ssSection.style.opacity = '1';
      this.ssSection.style.transform = moveFocus;
    } else if (status === 'error') {
      this.otpDisabled = true;
      this.ssSection.style.opacity = '0';
      this.ssSection.style.transform = moveLeft;
      this.tsSection.style.opacity = '1';
      this.tsSection.style.transform = moveFocus;
    }
  }

  back(): void {
    // ..
    if (this.status === 'error') {
      this.status = 'success';
      this.otpDisabled = false;
      this.tsSection.style.opacity = '0';
      this.tsSection.style.transform = 'translate(100%, 0%)';
      this.ssSection.style.opacity = '1';
      this.ssSection.style.transform = 'translate(0%, 0%)';
    }
  }
}
