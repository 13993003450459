<app-ke-wave [status]="status">
  <div>
    <h1 class="ke-purple-dark">Adesione richiesta</h1>
    <div class="ke-content ke-grey d-flex">
      <div>
        L'organizzazione richiede l'adesione al servizio di Firma Elettronica Avanzata. Per favore, compila i dati
        richiesti: </div>
    </div>
    <div class="ke-content ke-grey d-flex mt-3">
    
      <mat-form-field appearance="outline" class="mr-4" id="inputLuogoNascita">
        <mat-label class="c-inherit">Luogo di nascita</mat-label>
        <input id="luogoNascita" matInput type="text" placeholder="Luogo di nascita" [(ngModel)]="luogoNascita"
          autocomplete="off" required>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label class="c-inherit">Data di nascita</mat-label>
        <input id="dataNascita" matInput type="date" placeholder="Data di nascita" [(ngModel)]="dataNascita"
          autocomplete="off" required>

      </mat-form-field>
    </div>
    <div class="ke-content ke-grey d-flex">
      <div class="py-2h f-center">
        <button mat-raised-button color="primary" (click)="fileInput.click()" class="inputFile"
          [disabled]="docFrontBase64">{{docFrontBase64 ? 'Documento caricato correttamente' : 'Allega Documento di Riconoscimento'}}</button>
        <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" accept="image/*, .pdf">
      </div>
    </div>
    <div class="ke-content ke-grey d-flex">
      <div class="py-2h f-center">
        <button mat-raised-button color="primary" (click)="inviaAdesione()" class="inputFile"
          [disabled]="!luogoNascita || !dataNascita || loading">Salva informazioni</button>
          <div class="fill d-flex justify-content-center ml-3">
            <mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</app-ke-wave>
