import { AuthOtpDigitalDialogComponent } from './../../shared/components/dialog/auth-otp-digital-dialog/auth-otp-digital-dialog.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { StartDigitalSignatureRequest } from 'src/app/shared/classes/digital';
import { TransactionPagesDocument } from 'src/app/shared/classes/transaction-pages';
import { UtilService } from 'src/app/shared/services/util.service';
import { SignatureField } from 'src/app/shared/classes/sign';
import { NoteDialogComponent } from 'src/app/shared/components/dialog/note-dialog/note-dialog.component';

@Component({
  selector: 'app-document-view-digital',
  templateUrl: './document-view-digital.component.html',
  styleUrls: ['./document-view-digital.component.scss']
})
export class DocumentViewDigitalComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav') sidenav : MatSidenav;

  laptopQuery: MediaQueryList;
  mobileQuery: MediaQueryList;

  documents: TransactionPagesDocument[] = [];
  toolbarInfo: {document: TransactionPagesDocument; page: number;};
  totPages: number;
  hideProgressBar: boolean;
  zoom: number;
  fullHeight: boolean;
  showInfo: boolean;
  pageLoaded: boolean;

  private _zoomCounter: number;
  private _minZoom: number;
  private _maxZoom: number;
  private _showSidemenu: boolean;
  private _sidemenuTimeout;
  private _loading: boolean;
  private _geoInfo: string;

  get zoomCounter(): number { return this._zoomCounter; }
  get showSidemenu(): boolean { return this._showSidemenu; }
  set showSidemenu(val: boolean) {
    if (val && !this._sidemenuTimeout) { this._sidemenuTimeout = setTimeout(() => { this.showSidemenu = false }, 4000); }
    else if (!val) { this.clearsidemenuTimeout(); }
    this._showSidemenu = val;
  }

  constructor(
    private media: MediaMatcher,
    private api: ApiHttpService,
    private util: UtilService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.documents = [];
    this.toolbarInfo = { document: null, page: 0 };
    /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */
    this.mobileQuery = media.matchMedia(' (min-width: 320px) and (max-width: 480px)');
    this.laptopQuery = media.matchMedia('(min-width: 1025px)');
    this.laptopQuery.addEventListener('change', (e)=> e.matches ? this.sidenav.open() : this.sidenav.close());
    this._showSidemenu = true;
    this.zoom = .7;
    this._zoomCounter = .1;
    this._minZoom = .3;
    this._maxZoom = 1.7;
  }

  ngAfterViewInit(): void {
    // if(navigator.geolocation){
    //   navigator.geolocation.getCurrentPosition(geo => {
    //     this._geoInfo = `${geo.coords.latitude.toFixed(2)}°/${geo.coords.longitude.toFixed(2)}° ±${geo.coords.accuracy}m`;
    //   });
    // }
    this.api.startDigitalSignature$(new StartDigitalSignatureRequest()).subscribe(res => {
      switch (res.statusCode) {
        case 0 : // TUTTO APPOSTO
          if (res.backUrl) localStorage.setItem('backUrlSignature', res.backUrl);
          this.documents = [this.util.digitalConverter(res)];
          this.pageLoaded = true;
          this.totPages = this.documents.length ? this.documents[0].pagesBase64.length : 0;
          this.hideProgressBar = true;
          this._showSidemenu = false;
          this.showInfo = true;
          !this.laptopQuery.matches ? this.sidenav.close(): '';
          setTimeout(() => this.showInfo = false, 4500);
          break;
        case -2 : // Documento già firmato
          localStorage.removeItem('sessionDID');
          this.router.navigate(['report/signed']);
          break;
        default : // SE il token è scaduto o altro
          localStorage.removeItem('sessionDID');
          location.reload(); // TODO da verificare, chiedere cosa voler fare una volta scaduta la sessione.
      }
    });
  }

  ngOnInit(): void {}

  // metodo dedicato alla classe
  scrollBack(event): void {
    const dc: any = document.getElementById("documentsContainer");
    const st = event.target.scrollTop;
    // ciclo i documenti (article dentro main)
    for (let i = 0; i < dc.children.length; i++) {
      // arr = numero di pagine all'interno del doc corrente (array di div || figure || img)
      const arr = [...dc.children[i].children];
      // trovo la prima pagina che ha la posizione maggiore all'attuale scrollTop dell'elemento
      const el = arr.findIndex(c => ((c.offsetTop + c.offsetHeight) - c.offsetHeight/4) > st); // TODO da migliorare
      if (el > -1) { // se lo trovo dunque .. altrimenti continuo a cercare
        this.toolbarInfo.page = el + 1; // imposto il numero di pagina corrente(nella view), su toolbarInfo;
        this.toolbarInfo.document = this.documents[i]; // e il relativo documento;
        break;
      }
    }
  }

  thumbnailSelect(page, docCounter): void {
    if (!this.laptopQuery.matches) { this.sidenav.close(); }
    this.showSidemenu = true;
    this.toPage(page, docCounter);
  }

  toolbarPageCounterChange(event, docCounter: number): void {
    const val = event.target.value;
    const doc = this.documents.find(el => el.documentCounter === docCounter);
    // event.target.value = val.toString().replace(/^0+/, ''); // TODO rimuovere leading zero da input utente
    event.target.value = val <= 0 || (doc && val > doc.pages)? this.toolbarInfo.page: val;
    this.toolbarInfo.page = event.target.value;
    this.toPage(this.toolbarInfo, docCounter);
  }

  movetHandle(event): void {
    if(!this.showSidemenu && this.util.moveSpeed(event)) {
      this.showSidemenu = true;
    }
  }

  setZoom(val: number): void {
    this.zoom = val;
    this.zoom = (this.zoom < this._minZoom) ? this._minZoom : (this.zoom > this._maxZoom) ? this._maxZoom : this.zoom;
    this.fullHeight = false; // rimuove full-height class dalle img, priorità su heightWidthToggle;
  }


  heightWidthToggle(): void {
    if (this.fullHeight){
      this.setZoom(1); // setta fullHeight a false e adatta l'immagine in base alla larghezza;
    } else {
      this.fullHeight = true;
      this.zoom= .6;
      setTimeout(()=> this.toPage(this.toolbarInfo.page, this.toolbarInfo.document.documentCounter), 1);
    }
  }
  
  isSignPage(pageId: number, docId: number): boolean {
    return !!this.documents[docId].signatureFields.find(sf => sf.page === (pageId + 1) && !sf.signed);
  }

  getSignPageField(pageId: number, docId: number): SignatureField[] {
    return this.documents[docId].signatureFields.filter(sf => sf.page === (pageId+1));
  }

  sign(): void {
    this.dialog.open(AuthOtpDigitalDialogComponent, { disableClose: true});
  }

  hasSignPage(): boolean {
    let res = false;
    for(let tpd of this.documents){
      if (tpd.signatureFields) {
        res = !!tpd.signatureFields.find(sf => !sf.signed);
        if (res) break;
      }
    }
    return res;
  }

  hasRequiredSignPage(): boolean {
    let res = false;
    for(let tpd of this.documents){
      if (tpd.signatureFields) {
        res = !!tpd.signatureFields.find(sf => sf.required && !sf.signed);
        if(res) break;
      }
    }
    return res;
  }

  openNoteDialog(): void {
    this.dialog.open(NoteDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        title: "Rifiuto documento",
        body: "vuoi rifiutare il documento?",
        isDigital: true
      }
    });
  }

  navPages(c: number): void {
    let val =  (c > 0? this.toolbarInfo.page + 1 : this.toolbarInfo.page-1);
    this.toolbarInfo.page = (val > this.toolbarInfo.document.pages && this.toolbarInfo.document.documentCounter <  this.documents.length) ? 1 :
                            (val <= 0 && this.toolbarInfo.document.documentCounter > 1) ? this.documents[this.toolbarInfo.document.documentCounter-1].pages : 
                            (val <= 0 || val > this.toolbarInfo.document.pages)? this.toolbarInfo.page : val;
    this.toolbarInfo.document = (val > this.toolbarInfo.document.pages && this.toolbarInfo.document.documentCounter <  this.documents.length) ? 
                                 this.documents[this.toolbarInfo.document.documentCounter] :
                                 (val <= 0 && this.toolbarInfo.document.documentCounter > 1) ? this.documents[this.toolbarInfo.document.documentCounter-2] :
                                 this.toolbarInfo.document;  
    this.toPage(this.toolbarInfo.page, this.toolbarInfo.document.documentCounter); 
  }

  clearsidemenuTimeout(): void { clearTimeout(this._sidemenuTimeout); this._sidemenuTimeout = null;}

  mouseEnterInfo(): void {
    if(this.mobileQuery.matches) {
      this.showSidemenu = false;
    } else  {
      this._showSidemenu = true;
      this.clearsidemenuTimeout();
    }
  }

  toNextSignField(): void {
    let sfEl;
    const dc: any = document.getElementById("rightContent");
    for(let doc of this.documents){
      sfEl = doc.signatureFields && doc.signatureFields.find(sf => !sf.signed);
      if (sfEl){
        sfEl = document.getElementById('sf'+sfEl.signatureFieldID);
        sfEl.scrollIntoView(false);
        dc.scrollTop += window.innerHeight/2;
        break;
      }
    }
    if (!this.laptopQuery.matches) this.sidenav.close();
  }

  private toPage(page, docCounter): void {
    const el = document.getElementById('doc'+docCounter+'page'+page);
    if (el) { el.scrollIntoView(); }
  }


}
