import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let res = next.handle(req);
    if (!req.url.match(/noBlock/g)) {
      this.requests.push(req);
      // console.log('Numero di chiamate -> ' + this.requests.length);
      this.loaderService.isLoading.next(true);

      res = new Observable(observer => {
        const subscription = next.handle(req).subscribe(event => {
          if (event instanceof HttpResponse) { observer.next(event); }
        },
        err => { observer.error(err); },
        () => { observer.complete(); });

        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
    }
    return res;
  }

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) { this.requests.splice(i, 1); }
    // console.log('Numero di chiamate -> ' + this.requests.length);
    this.loaderService.isLoading.next(this.requests.length > 0);
  }
}
