import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

if (!localStorage.getItem('sessionOn') && !localStorage.getItem('sessionASID')){ 
      localStorage.clear(); 
}

const mySearchParams = new URLSearchParams(window.location.search);
if (mySearchParams.has('id')) { 
  localStorage.clear();
  localStorage.setItem('transactionID', mySearchParams.get('id')); 
} else if (mySearchParams.has('asid')) {
  localStorage.clear();
  localStorage.setItem('addSignatureID', mySearchParams.get('asid')); 
} else if (mySearchParams.has('did'))  {
  localStorage.clear();
  localStorage.setItem('digitalID', mySearchParams.get('did')); 
} else if (mySearchParams.has('adid')) {
  localStorage.clear();
  localStorage.setItem('transactionIDAdesioneFEA', mySearchParams.get('adid'))
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
