import { DocumentViewDigitalComponent } from './document/document-view-digital/document-view-digital.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { DocumentAddSignatureComponent } from './document/document-add-signature/document-add-signature.component';
import { DocumentReportComponent } from './document/document-report/document-report.component';
import { DocumentViewComponent } from './document/document-view/document-view.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DeprecatedComponent } from './shared/components/deprecated/deprecated.component';
import { AvaiableBrowserGuard } from './shared/guards/avaiable-browser.guard';
import { OldBrowserGuard } from './shared/guards/old-browser.guard';
import { AdesioneComponent } from './adesione/adesione.component';

const routes: Routes = [
  { path: 'deprecated', component: DeprecatedComponent, canActivate: [AvaiableBrowserGuard] },
  { path: 'auth', component: AuthComponent, canActivate: [OldBrowserGuard]},
  { path: 'adesione', component: AdesioneComponent, canActivate: [OldBrowserGuard]},
  { path: 'document', component: DocumentViewComponent, canActivate: [OldBrowserGuard] },
  { path: 'addSignature/:id', component: DocumentAddSignatureComponent, canActivate: [OldBrowserGuard] },
  { path: 'signDigital/:id', component: DocumentViewDigitalComponent, canActivate: [OldBrowserGuard] },
  { path: 'report', component: DocumentReportComponent, canActivate: [OldBrowserGuard] },
  { path: 'report/:status', component: DocumentReportComponent },
  { path: '**', component: NotFoundComponent } // NON TUILIZZATO, dato che è app.component a gestire l'entrata
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
