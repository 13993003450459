import { SignatureField } from "./sign";

export class StartDigitalSignatureResponse {
    message: string;
    statusCode: number;
    fileName: string;
    fullName: string;
    idTranaction: string;
    backUrl: string;
    pageImages: {imageBase64: string; pageNumber: number}[];
    pages: number;
    signatureField: SignatureField;
}

export class SignDigitalSignatureResponse {
    message: string;
    statusCode: number;
    pages: number;
}

export class StartDigitalSignatureRequest {
    idTransaction: string;
    constructor() {
        this.idTransaction = localStorage.getItem('digitalID');
    }
}

export class SignDigitalSignatureRequest {
    idTransaction: string;
    constructor(public otp: string) {
        this.idTransaction = localStorage.getItem('digitalID');
    }
}

export class RejectDigitalSignatureRequest {
    idTransaction: string;
    constructor(public motivo: string) {
        this.idTransaction = localStorage.getItem('digitalID');
    }
}

export class RejectDigitalSignatureResponse {
    message: string;
    statusCode: number;
}