import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

interface DialogData {
  title: string;
  body: string;
  obs: Observable<any>;
  optionalSignField: boolean;
  // obsCallback: Function;
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  loading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: DialogData,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    // private api: ApiHttpService
  ) { }

  get data(): DialogData { return this._data; }
  ngOnInit(): void {}
  next(): void {
    if (!this.loading) {
      if(this.data.obs){ 
        this.loading = true;
        this.data.obs.subscribe(data => {
          // if (this.data.obsCallback) { this.data.obsCallback.call(data, [this.api]); }
          // else {this.dialogRef.close(data);}
          this.dialogRef.close(data);
        }, err => this.loading = false);
      } else { this.dialogRef.close(true); }
    }
  }
  otionalNext(): void {
    if(!this.loading){
      this.dialogRef.close('optional');
    }
  }

}
