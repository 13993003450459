<div class="f-center justify-content-between flex-wrap">
    <h2 mat-dialog-title class="ke-green mb-3">Aggiungi punto firma</h2>
    <mat-checkbox [(ngModel)]="required" color="warn" class="mb-3">Firma obligatoria?</mat-checkbox>
</div>

<mat-dialog-content class="mat-typography">
    <!-- <div class="d-flex flex-column">
        <mat-checkbox [checked]="isIn(signer)" (change)="add($event, signer)" color="primary" *ngFor="let signer of data.signers; last as isLast" class="w-100" [class.mb-2]="!isLast">
            <span>{{ signer.name + ' ' + signer.lastname }}</span>
            <span class="f-right ke-blue">{{ signer.email }}</span>
        </mat-checkbox>
    </div> -->
    <mat-radio-group [(ngModel)]="signer" color="primary" class="d-flex flex-column">
        <mat-radio-button [value]="s" *ngFor="let s of data.signers" class="mb-2" >
            {{s.name + ' ' + s.lastname}}
        </mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Annulla</button>
    <button mat-button color="primary" (click)="next()">Continua</button>
    <span class="fill"></span>
</mat-dialog-actions>