<div class="p-relative overflow-h"> 
    <div #fistStep class="firstStep">
        
            <div class="stepper">
                <div class="step" [class.fadeLeftOut]="status === 'error'">
                    <h2 mat-dialog-title class="ke-green m-0">Autenticazione richiesta</h2>
                </div>
                <div class="step out" [class.fadeRightIn]="status === 'error'">
                    <h2 mat-dialog-title class="ke-red m-0">Codice OTP Errato</h2>
                </div>
            </div>

            <div>{{errorMessage || 'Inserire il codice OTP generato dal proprio dispositivo:'}}</div>

            <div [ngClass]="{'ke-green': (status==='success'), 'ke-red': (status==='error')}">
                <mat-form-field appearance="outline">
                    <mat-label class="c-inherit">Codice OTP</mat-label>
                    <input id="otpInput"
                            #otpInput="ngModel"
                            matInput
                            max="6"
                            maxlength="6"
                            [disabled]="otpDisabled"
                            type="text"
                            pattern="\d{6}"
                            [(ngModel)]="otp"
                            (keypress)="inputFormat()"
                            autocomplete="off">
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <mat-error *ngIf="otpInput.hasError('pattern')">Codice di 6 cifre</mat-error>
                </mat-form-field>
            </div>
        

        <div class="f-center">
            <button mat-button mat-dialog-close class="mr-2">Annulla</button>
            <button mat-button 
                    [disabled]="!otp || ((otp+'').length < 6)"
                    [color]="status === 'error' ? 'warn' : 'primary'"
                    (click)="autoAuth()">{{status === 'error' ? 'Riprova' : 'Autenticati'}}</button>
            <div class="fill d-flex pl-2r">
                <mat-spinner class="green" *ngIf="loading" diameter="25"></mat-spinner>
            </div>
        </div>
    </div>

    <div #secondStep class="secondStep">
        <h2 mat-dialog-title class="ke-red">Si è verificato un errore.</h2>
        <mat-dialog-content class="ke-grey">
            <p>Riprova più tardi, se il problema persiste, contattare l'amministratore.</p>
        </mat-dialog-content>
        <div class="py-2h mt-2h button-load-r">
        <button mat-button mat-dialog-close class="mr-2">Annulla</button>
            <button mat-raised-button 
                    color="warn"
                    class="bump-button"
                    (click)="back()"
                    [disabled]="!otpDisabled">Riprova</button>
            <div class="fill d-flex justify-content-center">
                <mat-spinner class="red" *ngIf="loading" diameter="25"></mat-spinner>
            </div>
        </div>
    </div>
</div>