import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { ApiHttpService } from '../../core/services/api-http.service';
import { PageRequest, PageResponse } from '../../shared/classes/page';
import { SignatureField, SignatureFieldsRequest, SignatureFieldsResponse, SignDocumentRequest, SigningMode } from '../../shared/classes/sign';
import { TransactionPagesDocument, TransactionPagesRequest } from '../../shared/classes/transaction-pages';
import { AuthOtpDialogComponent } from '../../shared/components/dialog/auth-otp-dialog/auth-otp-dialog.component';
import { ConfirmDialogComponent } from '../../shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { NoteDialogComponent } from '../../shared/components/dialog/note-dialog/note-dialog.component';
import { UtilService } from '../../shared/services/util.service';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  laptopQuery: MediaQueryList;
  mobileQuery: MediaQueryList;
  documents: TransactionPagesDocument[];
  toolbarInfo: { document: TransactionPagesDocument; page: number; };
  totPages: number;
  hideProgressBar: boolean;
  zoom: number;
  fullHeight: boolean;
  showInfo: boolean;
  pageLoaded: boolean;

  private _zoomCounter: number;
  private _minZoom: number;
  private _maxZoom: number;
  private _showSidemenu: boolean;
  private _sidemenuTimeout;
  private _loading: boolean;
  private _geoInfo: string;

  get zoomCounter(): number { return this._zoomCounter; }
  get showSidemenu(): boolean { return this._showSidemenu; }
  set showSidemenu(val: boolean) {
    if (val && !this._sidemenuTimeout) { this._sidemenuTimeout = setTimeout(() => { this.showSidemenu = false }, 4000); }
    else if (!val) { this.clearsidemenuTimeout(); }
    this._showSidemenu = val;
  }
  get isAdesione(): boolean {
    return !!localStorage.getItem("transactionIDAdesioneFEA");
  }

  constructor(
    private media: MediaMatcher,
    private api: ApiHttpService,
    private util: UtilService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.documents = [];
    this.toolbarInfo = { document: null, page: 0 };
    /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */
    this.mobileQuery = media.matchMedia(' (min-width: 320px) and (max-width: 480px)');
    this.laptopQuery = media.matchMedia('(min-width: 1025px)');
    this.laptopQuery.addEventListener('change', (e) => e.matches ? this.sidenav.open() : this.sidenav.close());
    this._showSidemenu = true;
    this.zoom = .7;
    this._zoomCounter = .1;
    this._minZoom = .3;
    this._maxZoom = 1.7;
  }
  // INIT APP
  ngAfterViewInit(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geo => {
        this._geoInfo = `${geo.coords.latitude.toFixed(2)}°/${geo.coords.longitude.toFixed(2)}° ±${geo.coords.accuracy}m`;
      });
    }
    this.api.getTransactionDocumentsInfo$(TransactionPagesRequest.make()).subscribe(tpRes => {
      if (tpRes.status === -1) { // Token non valido
        localStorage.removeItem('sessionOn');
        location.reload(); // TODO da verificare, chiedere cosa voler fare una volta scaduta la sessione.
      } else {
        let docsAndFields: Observable<[PageResponse[], SignatureFieldsResponse]>[] = [];
        if (tpRes.documents) {
          let docsObs: Observable<PageResponse[]>[] = [];
          this.documents = tpRes.documents;
          if (this.documents.length) { this.toolbarInfo = { document: this.documents[0], page: 1 } }
          this.documents.forEach((doc, i) => {
            this.totPages += doc.pages;
            let pageObs: Observable<PageResponse>[] = [];
            this.documents[i].pagesBase64 = []; // custom initialization
            for (let ii = 1; ii <= doc.pages; ii++) {
              pageObs.push(this.api.getPage$(PageRequest.make(ii, doc.documentID)));
            }
            docsObs.push(combineLatest(pageObs));
            let signFields = this.api.getSignatureFields$(SignatureFieldsRequest.make(doc.documentID));
            docsAndFields.push(combineLatest([docsObs[i], signFields]));
          });
          combineLatest(docsAndFields).subscribe(data => {
            for (let i = 0; i < data.length; i++) {
              const docInfos = data[i];
              // data[i][0] = PageResponse[];
              // data[i][1] = SignatureFieldsResponse;
              docInfos[0].forEach((el, index) => this.documents[i].pagesBase64[index] = el.pageBase64);
              this.documents[i].signatureFields = docInfos[1].signatureFields;
            }
            this.hideProgressBar = true;
            this._showSidemenu = false;
            this.showInfo = true;
            !this.laptopQuery.matches ? this.sidenav.close() : '';
            this.pageLoaded = true;
            setTimeout(() => this.showInfo = false, 4500);
          });
        }
      }
    });
  }

  ngOnInit(): void { }

  // metodo dedicato alla classe
  scrollBack(event): void {
    const dc: any = document.getElementById("documentsContainer");
    const st = event.target.scrollTop;
    // ciclo i documenti (article dentro main)
    for (let i = 0; i < dc.children.length; i++) {
      // arr = numero di pagine all'interno del doc corrente (array di div || figure || img)
      const arr = [...dc.children[i].children];
      // trovo la prima pagina che ha la posizione maggiore all'attuale scrollTop dell'elemento
      const el = arr.findIndex(c => ((c.offsetTop + c.offsetHeight) - c.offsetHeight / 4) > st); // TODO da migliorare
      if (el > -1) { // se lo trovo dunque .. altrimenti continuo a cercare
        this.toolbarInfo.page = el + 1; // imposto il numero di pagina corrente(nella view), su toolbarInfo;
        this.toolbarInfo.document = this.documents[i]; // e il relativo documento;
        break;
      }
    }
  }
  //MINIATURA CLICCATA
  thumbnailSelect(page, docCounter): void {
    if (!this.laptopQuery.matches) { this.sidenav.close(); }
    this.showSidemenu = true;
    this.toPage(page, docCounter);
  }
  //CAMBIO INDICATORE PAGINA TOOLBAR
  toolbarPageCounterChange(event, docCounter: number): void {
    const val = event.target.value;
    const doc = this.documents.find(el => el.documentCounter === docCounter);
    // event.target.value = val.toString().replace(/^0+/, ''); // TODO rimuovere leading zero da input utente
    event.target.value = val <= 0 || (doc && val > doc.pages) ? this.toolbarInfo.page : val;
    this.toolbarInfo.page = event.target.value;
    this.toPage(this.toolbarInfo, docCounter);
  }
  //MOVIMENTO MOUSE PER VISUALIZZARE TOOLKIT
  movetHandle(event): void {
    if (!this.showSidemenu && this.util.moveSpeed(event)) {
      this.showSidemenu = true;
    }
  }

  setZoom(val: number): void {
    this.zoom = val;
    this.zoom = (this.zoom < this._minZoom) ? this._minZoom : (this.zoom > this._maxZoom) ? this._maxZoom : this.zoom;
    this.fullHeight = false; // rimuove full-height class dalle img, priorità su heightWidthToggle;
  }
  //BOTTONE ESPANDI/RIDUCI
  heightWidthToggle(): void {
    if (this.fullHeight) {
      this.setZoom(1); // setta fullHeight a false e adatta l'immagine in base alla larghezza;
    } else {
      this.fullHeight = true;
      this.zoom = .6;
      setTimeout(() => this.toPage(this.toolbarInfo.page, this.toolbarInfo.document.documentCounter), 1);
    }
  }
  //TORNA TRUE SE LA PAGINA ESISTE ED E' DA FIRMARE
  isSignPage(pageId: number, docId: number): boolean {
    return !!this.documents[docId].signatureFields.find(sf => sf.page === (pageId + 1) && !sf.signed);
  }

  getSignPageField(pageId: number, docId: number): SignatureField[] {
    return this.documents[docId].signatureFields.filter(sf => sf.page === (pageId + 1));
  }
  //BOTTONE PUNTO FIRMA
  sign(signFieldId: number, pageNumber: number, documentID: number): void {
    switch (localStorage.getItem('signingMode')) {
      case SigningMode.CLICK_SIGN: this.clickSign(signFieldId, pageNumber, documentID); break;
      case SigningMode.OTP_SIGN: this.otpSign(signFieldId, pageNumber, documentID, this._geoInfo); break;
      default: break;
    }
  }
  
  //TORNA TRUE SE HA UN PUNTO FIRMA
  hasSignPage(): boolean {
    let res = false;
    for (let tpd of this.documents) {
      if (tpd.signatureFields) {
        res = !!tpd.signatureFields.find(sf => !sf.signed);
        if (res) break;
      }
    }
    return res;
  }

  //TORNA TRUE SE ESISTE UN PUNTO FIRMA OBBLIGATORIO
  hasRequiredSignPage(): boolean {
    // if(this._loading) return true;
    let res = false;
    for (let tpd of this.documents) {
      if (tpd.signatureFields) {
        res = !!tpd.signatureFields.find(sf => sf.required && !sf.signed);
        if (res) break;
      }
    }
    return res;
  }

  //BOTTONE RIFIUTA
  openNoteDialog(): void {
    const dialogRef = this.dialog.open(NoteDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        title: "Rifiuto documento",
        body: "vuoi rifiutare il documento?",
      }
    });
  }

  navPages(c: number): void {
    let val = (c > 0 ? this.toolbarInfo.page + 1 : this.toolbarInfo.page - 1);
    this.toolbarInfo.page = (val > this.toolbarInfo.document.pages && this.toolbarInfo.document.documentCounter < this.documents.length) ? 1 :
      (val <= 0 && this.toolbarInfo.document.documentCounter > 1) ? this.documents[this.toolbarInfo.document.documentCounter - 1].pages :
        (val <= 0 || val > this.toolbarInfo.document.pages) ? this.toolbarInfo.page : val;
    this.toolbarInfo.document = (val > this.toolbarInfo.document.pages && this.toolbarInfo.document.documentCounter < this.documents.length) ?
      this.documents[this.toolbarInfo.document.documentCounter] :
      (val <= 0 && this.toolbarInfo.document.documentCounter > 1) ? this.documents[this.toolbarInfo.document.documentCounter - 2] :
        this.toolbarInfo.document;
    this.toPage(this.toolbarInfo.page, this.toolbarInfo.document.documentCounter);
  }

  complete(): void {
    if (!this._loading) {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: "Completa",
          body: "sei sicuro di voler completare la pratica?" + (this.hasSignPage() ? "<br>ci sono altri campi da poter firmare" : ""),
          optionalSignField: this.hasSignPage()
        }
      });
      dialog.beforeClosed().subscribe(result => {
        if (result === true) { // controllo che sia un boolean e che sia true
          this.close();
        } else if (result === 'optional') this.toNextSignField();
      });
    }
  }

  clearsidemenuTimeout(): void { clearTimeout(this._sidemenuTimeout); this._sidemenuTimeout = null; }

  mouseEnterInfo(): void {
    if (this.mobileQuery.matches) {
      this.showSidemenu = false;
    } else {
      this._showSidemenu = true;
      this.clearsidemenuTimeout();
    }
  }

  //SCROLLA AL PRIMO PUNTO FIRMA DISPONIBILE
  toNextSignField(): void {
    let sfEl;
    const dc: any = document.getElementById("rightContent");
    for (let doc of this.documents) {
      sfEl = doc.signatureFields && doc.signatureFields.find(sf => !sf.signed);
      if (sfEl) {
        sfEl = document.getElementById('sf' + sfEl.signatureFieldID);
        sfEl.scrollIntoView(false);
        dc.scrollTop += window.innerHeight / 2;
        break;
      }
    }
    if (!this.laptopQuery.matches) this.sidenav.close();
  }

  //FIRMA CLICCA PER FIRMARE
  private clickSign(sfID: number, pageNumber: number, documentID: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: "Conferma firma",
        body: "sei sicuro di voler firmare il documento?",
        obs: this.api.signDocument$(SignDocumentRequest.make(sfID, "", 0, this._geoInfo))
      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      if (result.status === 0) { this.signDocumentCallback(sfID, pageNumber, documentID); }
      else if (result.status === -1) {// Token non valido
        localStorage.removeItem('sessionOn');
        location.reload(); // TODO da verificare, chiedere cosa voler fare una volta scaduta la sessione.
      }
    });
  }

  //FIRMA OTP
  private otpSign(signatureFieldID: number, pageNumber: number, documentID: number, gpsInfo: string): void {
    const dialog = this.dialog.open(AuthOtpDialogComponent, {
      disableClose: true,
      data: { signatureFieldID, gpsInfo }
    });
    dialog.beforeClosed().subscribe(res => {
      if (res) { this.signDocumentCallback(signatureFieldID, pageNumber, documentID); }
    });
  }
  private signDocumentCallback(sfID: number, pageNumber: number, documentID: number): void {
    const docIndex = this.documents.findIndex(doc => doc.documentID === documentID);
    const sfIndex = this.documents[docIndex].signatureFields.findIndex(sf => sf.signatureFieldID === sfID);
    this.documents[docIndex].signatureFields[sfIndex].signed = true;
    if (!this.hasSignPage()) { this.close(); }
    else {
      this.api.getPage$(PageRequest.make(pageNumber, documentID)).subscribe(pRes => {
        this.documents[docIndex].pagesBase64[pageNumber - 1] = pRes.pageBase64;
        this.toPage(pageNumber, this.documents[docIndex].documentCounter);
        // if (this.mobileQuery.matches && !this.hasSignPage()) {
        //   this.sidenav.open();
        //   this.snackBar.open("Tutti i campi sono stati firmati, per continuare premi CONFERMA", '', {
        //     verticalPosition: 'top',
        //     horizontalPosition: 'center',
        //     duration: 4500,
        //     panelClass: ['ke-bg-green', 'white']
        //   });
        // }
      });
    }
  }
  //SCROLL ALLA PAGINA CHIAMATA
  private toPage(page, docCounter): void {
    const el = document.getElementById('doc' + docCounter + 'page' + page);
    if (el) { el.scrollIntoView(); }
  }
  //CHIUDE LA PRATICA E NAVIGA ALLA PAGINA DI REPORT
  private close(): void {
    this._loading = true;
    this.api.closeTransaction$(TransactionPagesRequest.make()).subscribe(
      data => {
        this._loading = false;
        if (data) {
          if (localStorage.getItem('transactionIDAdesioneFEA')) {
            localStorage.removeItem('transactionIDAdesioneFEA')
            window.location.reload();
          } else {
            this.router.navigate(['report', 'signed']);
          }
        }
      },
      err => { this._loading = false; }
    );
  }

}