import { SignatureField } from "./sign";

export class TransactionRequest {
    transactionID: string;
    // transactionIDAdesioneFEA: string;
    static make(transactionID?: string): TransactionRequest{
        return { transactionID: transactionID || localStorage.getItem('transactionID') };
    }
}
export class TransactionResponse {
    desc: string;
    operationID: string;
    status: number;
}

export class TransactionPagesRequest {
    tokenFE: string;
    transactionID: string;
    static make(): TransactionPagesRequest {
        return {
            tokenFE: localStorage.getItem('tokenFE'),
            transactionID: localStorage.getItem('transactionIDAdesioneFEA') ? localStorage.getItem('transactionIDAdesioneFEA') : localStorage.getItem('transactionID')
        }
    }
}

export class TransactionPagesResponse {
    documents: TransactionPagesDocument[];
    error: string;
    status: number;
}

export class TransactionPagesDocument {
    documentCounter: number;
    documentID: number;
    fileName: string;
    pages: number;
    pagesBase64?: string[]; // custom FE
    signatureFields?: SignatureField[]; // custom FE

}

export class OpenTransactionRequest {
    operationID: string;
    transactionID: string;
    otp: number;
    static make(operationID: string, otp: number, transactionID?: string): OpenTransactionRequest {
        return { operationID, otp, transactionID: transactionID || localStorage.getItem('transactionID') }
    }
}

export class RefuseTransactionRequest {
    message: string;
    tokenFE: string;
    transactionID: string;
    static make(message?: string): RefuseTransactionRequest {
        return{
            message,
            tokenFE: localStorage.getItem('tokeFE'),
            transactionID: localStorage.getItem('transactionID')
        }
    }
}