<h2 mat-dialog-title class="ke-green">{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
    <p [innerHTML]="data.body"> </p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close [disabled]="loading">Annulla</button>
    <button mat-flat-button color="primary" (click)="next()" cdkFocusInitial>Continua</button>
    <span class="fill"></span>
    <button *ngIf="data.optionalSignField" mat-button color="primary" (click)="otionalNext()" >Firme opzionali</button>
    <mat-spinner *ngIf="loading" class="green" diameter="25"></mat-spinner>
</mat-dialog-actions>