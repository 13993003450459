import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHttpService } from './core/services/api-http.service';
import { SigningModeRequest } from './shared/classes/sign';
import { OpenTransactionRequest, TransactionRequest } from './shared/classes/transaction-pages';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  transactionID: string;
  addSignatureID: string;
  digitalID: string;
  transactionIDAdesioneFEA: string;

  private data: string

  constructor(
    private router: Router,
    private api: ApiHttpService
  ) {}

  ngOnInit(): void {
    this.transactionID = localStorage.getItem('transactionID');
    this.addSignatureID = localStorage.getItem('addSignatureID');
    this.digitalID = localStorage.getItem('digitalID');
    this.transactionIDAdesioneFEA = localStorage.getItem('transactionIDAdesioneFEA')
    if (localStorage.getItem('sessionOn')) { this.router.navigate(['document']); }
    else if (localStorage.getItem('sessionASID')) { this.router.navigate(['addSignature', this.addSignatureID]); }
    else if (localStorage.getItem('sessionDID')) { this.router.navigate(['signDigital', this.digitalID]); }
    else if (this.transactionID) { // <--- TODO this.transactionID
      // this.transactionID = this.transactionID.replace(/\s/g, '+'); // DEPRECATO (GLI ID PASSATI  NON CONTENGONO CARATTERI SPECIALI)
      const query = TransactionRequest.make(this.transactionID);
      this.api.getTransactionStatus$(query).subscribe(data => {
        if (data.transactionIDAdesioneFEA) {
          localStorage.setItem("transactionIDAdesioneFEA", data.transactionIDAdesioneFEA);
        }
        this.transactionStatusCallback(data)}
        );
    } else if (this.digitalID) {
      localStorage.setItem('sessionDID', "true");
      this.router.navigate(['signDigital', this.digitalID]);
    } else if (this.addSignatureID) {
      localStorage.setItem('sessionASID', "true");
      this.router.navigate(['addSignature', this.addSignatureID]);
      // Naviga verso il componente DocumentReport senza 'status', si può sostituire
      // con un cmp che segnala che l'id non è presente.
    } else { this.router.navigate(['report']); } // <--- TODO 'report' || ''
  }

  private transactionStatusCallback(data: any): void {
    this.data = data;
    switch (data.status) {
      case 0 : this.router.navigate(['report/signed']); break;
      case -1: this.next(); break;
      case -2: this.router.navigate(['report/rejected']); break;
      case -4: this.router.navigate(['report/deleted']); break;
      case -5: this.router.navigate(['report/busy']); break;
      default: this.router.navigate(['report']); break; // -9 || -3 || else
    }
  }

  private next(): void {
    this.api.getAuthRequired$({transactionID: this.transactionID}).subscribe(data => {
      localStorage.setItem('userTransactionStatus', JSON.stringify(this.data));
      switch (data.status) {
        case 0:  // Auth non richiesto
        {
          this.api.openTransaction$(OpenTransactionRequest.make("", 0, this.transactionID)).subscribe(otRes => {
            if (otRes.status === 0){
              localStorage.setItem('tokenFE', otRes.tokenFE); 
              localStorage.setItem('sessionOn', "true");
              // ASYNC
              this.api.getSigningMode$(SigningModeRequest.make()).subscribe(smRes => localStorage.setItem('signingMode', smRes.signingMode)); // Chiamarlo dopo aver slavato il tokenFE
              if (localStorage.getItem('transactionIDAdesioneFEA')) {
                this.router.navigate(['adesione'])
              } else {
                this.router.navigate(['document']); 
              }
            }
          });
        }
        break;
        case 1: { // Autenticazione richiesta
          this.router.navigate(['auth']); // <-- 'auth'
        }; break;
      }
    });
  }
}

