import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-kefirma-button-fab',
  templateUrl: './kefirma-button-fab.component.html',
  styleUrls: ['./kefirma-button-fab.component.scss']
})
export class KefirmaButtonFabComponent implements OnInit {

  // @Output() click: EventEmitter<void>;
  @Input() set value(val: number) { this._value = val > 99? '99+' : val + ''; }
  get value() { return this._value; }

  kedosLogoEmptyPath: string;
  kedosLogoPath: string;

  private _value;

  constructor() {
    // this.click = new EventEmitter();
    this.kedosLogoEmptyPath = 'assets/img/kefirma-logo-empty.png';
    this.kedosLogoPath = 'assets/img/kefirma-logo.png';
   }

  ngOnInit(): void {}
  // clicked(): void { this.click.emit(); }
}
