import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'; 
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './core/interceptors/api-interceptor';
import { DocumentReportComponent } from './document/document-report/document-report.component';
import { LoaderService } from './core/services/loader.service';
import { LoaderInterceptor } from './core/interceptors/loader-interceptor';
import { AuthComponent } from './auth/auth.component';
import { FormsModule } from '@angular/forms';
import { DocumentViewComponent } from './document/document-view/document-view.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Injectable } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';



import * as Hammer from 'hammerjs';
import { NotFoundComponent } from './not-found/not-found.component';
import { DocumentAddSignatureComponent } from './document/document-add-signature/document-add-signature.component';
import { AvaiableBrowserGuard } from './shared/guards/avaiable-browser.guard';
import { OldBrowserGuard } from './shared/guards/old-browser.guard';
import { DocumentViewDigitalComponent } from './document/document-view-digital/document-view-digital.component';
import { AdesioneComponent } from './adesione/adesione.component';

@Injectable()
export class KeHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
    tap: { tapCount: 3 }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    DocumentReportComponent,
    AuthComponent,
    DocumentViewComponent,
    NotFoundComponent,
    DocumentAddSignatureComponent,
    DocumentViewDigitalComponent,
    AdesioneComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSidenavModule,
    HammerModule,
    DragDropModule
  ],
  providers: [
    LoaderService,
    AvaiableBrowserGuard,
    OldBrowserGuard,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: KeHammerConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
