import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-report',
  templateUrl: './document-report.component.html',
  styleUrls: ['./document-report.component.scss']
})
export class DocumentReportComponent implements OnInit, AfterViewInit {

  status: string;
  email: string;

  private _backUrlSignature: string;

  constructor(private route: ActivatedRoute) { }
  
  ngOnInit(): void {
    this.status = this.route.snapshot.paramMap.get('status');
    this.email = JSON.parse(localStorage.getItem("userTransactionStatus"))?.email || '';
    this._backUrlSignature = localStorage.getItem('backUrlSignature');
    if (this.email && this.email.indexOf("@") > -1) {
      const addr = this.email.split("@")[0];
      const mail = this.email.split("@")[1];
      this.email = (addr.length > 7 ? `${addr.slice(0,3)}***${addr.slice(-3)}` : `${addr[0]}***${addr.slice(-1)}`) +'@'+ mail;
    }
  }
  
  ngAfterViewInit(): void {
    if(this._backUrlSignature) {
      setTimeout(() => location.assign(this._backUrlSignature), 1500);
    }
    localStorage.clear();
  }

}
