import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiHttpService } from '../core/services/api-http.service';
import { AdesioneModeRequest } from '../shared/classes/sign';

@Component({
  selector: 'app-adesione',
  templateUrl: './adesione.component.html',
  styleUrls: ['./adesione.component.scss']
})
export class AdesioneComponent implements OnInit {

  constructor(private api: ApiHttpService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  loading: boolean = false;
  dataNascita: string;
  luogoNascita: string;
  docFrontBase64: string
  docRearBase64: string;
  status: string;
  srcResult: any;
  fileInput: any;

  onFileSelected(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
        this.docFrontBase64 = window.btoa(String(reader.result));
      };
  }

  inviaAdesione() {
    this.loading = true;
    this.api.saveAdesione$(AdesioneModeRequest.make(this.luogoNascita, this.dataNascita, this.docFrontBase64, this.docRearBase64)).subscribe(res => {
      if (res.status == 0) {
        this.router.navigate(['document']);
      }
    },
    error => {},
    () => {
      this.loading = false;
    })
  }
}
