import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Signature, Signer } from 'src/app/shared/classes/document';

interface SheetData {
  signature: Signature;
} 

@Component({
  selector: 'app-detail-sheet',
  templateUrl: './detail-sheet.component.html',
  styleUrls: ['./detail-sheet.component.scss']
})
export class DetailSheetComponent implements OnInit {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: SheetData,
    private sheetRef: MatBottomSheetRef<DetailSheetComponent>,

  ) { }

  ngOnInit(): void {
  }

  deleteSign(): void { this.sheetRef.dismiss('delete'); }
  edit(): void { this.sheetRef.dismiss('edit'); }
  close(): void { this.sheetRef.dismiss(); }


}
