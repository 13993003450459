import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-confirm-sheets',
  templateUrl: './confirm-sheets.component.html',
  styleUrls: ['./confirm-sheets.component.scss']
})
export class ConfirmSheetsComponent implements OnInit {

  constructor(
    private sheetRef: MatBottomSheetRef<ConfirmSheetsComponent>,
  ) { }

  ngOnInit(): void {
  }
  next(): void {
    this.sheetRef.dismiss(true);
  }

}
