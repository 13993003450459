import { AuthComponent } from './../../../../auth/auth.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { SignOperationRequest, SignDocumentRequest } from 'src/app/shared/classes/sign';
import { SignDigitalSignatureRequest } from 'src/app/shared/classes/digital';


interface DialogData {
  signatureFieldID: number;
  gpsInfo: string;
}

@Component({
  selector: 'app-auth-otp-digital-dialog',
  templateUrl: './auth-otp-digital-dialog.component.html',
  styleUrls: ['./auth-otp-digital-dialog.component.scss']
})
export class AuthOtpDigitalDialogComponent extends AuthComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: DialogData,
    private dialog: MatDialogRef<AuthOtpDigitalDialogComponent>,
    private a: ApiHttpService,
    private r: Router
  ){ super(a, r); }

  ngOnInit(): void {
    this.otpDisabled = false;
    this.status = 'success';
  }

  autoAuth(): void {
    if (!this.loading) {
      this.loading = true;
      this.a.signDigitalSignature$(new SignDigitalSignatureRequest(this.otp + '')).subscribe(res => {
        if (res.statusCode === 0){
           this.r.navigate(['/report/signed']);
           this.dialog.close(true);
          }
        else {
          this.errorMessage = res.message;
          this.status = 'error';
        }
      }, err => this.next('error'), () => this.loading = false);
    }
  }

  next(status: 'success' | 'error' ): void {
    const moveLeft = 'translate(-100%, 0%)';
    const moveFocus = 'translate(0%, 0%)';
    this.status = status;
    this.otpDisabled = true;

    this.fsSection.style.opacity = '0';
    this.fsSection.style.transform = moveLeft;
    this.ssSection.style.opacity = '1';
    this.ssSection.style.transform = moveFocus;
    
  }

  back(): void {
    // ..
    if (this.status === 'error') {
      this.status = 'success';
      this.errorMessage = '';
      this.otpDisabled = false;
      this.ssSection.style.opacity = '0';
      this.ssSection.style.transform = 'translate(100%, 0%)';
      this.fsSection.style.opacity = '1';
      this.fsSection.style.transform = 'translate(0%, 0%)';
    }
  }

}
