import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OpenTransactionRequest, RefuseTransactionRequest, TransactionPagesRequest, TransactionPagesResponse, TransactionRequest, TransactionResponse } from 'src/app/shared/classes/transaction-pages';
import { environment } from 'src/environments/environment';
import { AdesioneModeRequest, SignatureFieldsRequest, SignatureFieldsResponse, SignDocumentRequest, SigningModeRequest, SigningModeResponse, SignOperationRequest } from 'src/app/shared/classes/sign';
import { PageRequest, PageResponse } from 'src/app/shared/classes/page';
import { map } from 'rxjs/operators';
import { EndAddSignatureRequest, StartAddSignatureResponse } from 'src/app/shared/classes/document';
import { RejectDigitalSignatureRequest, RejectDigitalSignatureResponse, SignDigitalSignatureRequest, SignDigitalSignatureResponse, StartDigitalSignatureRequest, StartDigitalSignatureResponse } from 'src/app/shared/classes/digital';

/**
 * In Angular 4.3, the HttpModule became legacy, and the new HttpClientModule became the default module for making API calls. 
 * If something like this happens again, you won’t need to change the use of the module in every component but only in this file. 
 * The upgrade will be easier.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {

  tokenFE: string;

  constructor(private http: HttpClient) { }

  private get(url: string, options?: any, noBlock: boolean = false, isDigital?: boolean): Observable<any> { 
    const endpoint = (isDigital? environment.apiUrlDigital : environment.apiUrl) + url + (noBlock ? '?noBlock' : '');
    return this.http.get(endpoint, options); 
  }
  private post(url: string, query: any, options?: any, noBlock: boolean = false, isDigital?: boolean): Observable<any> { 
    const endpoint = (isDigital? environment.apiUrlDigital : environment.apiUrl) + url + (noBlock ? '?noBlock' : '');
    return this.http.post(endpoint, query, options); 
  }
  // private put(url: string, data: any, options?: any): Observable<any> { return this.http.put(environment.apiUrl + url, data, options); }
  // private delete(url: string, options?: any): Observable<any> { return this.http.delete(environment.apiUrl + url, options); }

  getTransactionStatus$(query: TransactionRequest, noBlock: boolean = false): Observable<any> {
    return this.post('GetTransactionStatus', query, {}, noBlock);
  }

  getAuthRequired$(query: TransactionRequest): Observable<any> {
    return this.post('GetAuthRequired', query);
  }

  startAuthOperation$(query: TransactionRequest, noBlock: boolean = false): Observable<TransactionResponse> {
    return this.post('StartAuthOperation', query, {}, noBlock);
  }

  openTransaction$(query: OpenTransactionRequest, noBlock: boolean = false): Observable<any> {
    return this.post('OpenTransaction', query, {}, noBlock);
  }

  getSigningMode$(query: SigningModeRequest): Observable<SigningModeResponse> {
    return this.post('GetSigningMode', query);
  }

  getPage$(query: PageRequest): Observable<PageResponse> {
    return this.post('GetPage', query, {}, true);
  }

  getTransactionDocumentsInfo$(query: TransactionPagesRequest): Observable<TransactionPagesResponse> {
    return this.post('GetTransactionDocumentsInfo', query, {}, true);
  }

  getSignatureFields$(query: SignatureFieldsRequest): Observable<SignatureFieldsResponse> {
    return this.post('GetSignatureFields', query, {}, true);
  }

  signDocument$(query: SignDocumentRequest): Observable<any> {
    return this.post('SignDocument', query, {}, true);
  }

  refuseTransaction$(query: RefuseTransactionRequest): Observable<boolean> {
    return this.post('RefuseTransaction', query, {}, false).pipe(map(res => res.status === 0));
  }

  closeTransaction$(query: TransactionPagesRequest, noBlock: boolean = false): Observable<boolean> {
    return this.post('CloseTransaction', query, {}, noBlock).pipe(map(res => res.status === 0));
  }

  startSignOperation$(query: SignOperationRequest): Observable<TransactionResponse>{
    return this.post('StartSignOperation', query, {}, true);
  }

  startAddSignature$(folderUUID: string): Observable<StartAddSignatureResponse> {
    return this.post('StartAddSignature', {folderUUID}, {}, true);
  }
  endAddSignature$(query: EndAddSignatureRequest): Observable<any> {
    return this.post('EndAddSignature', query);
  }

  // DIGITAL
  startDigitalSignature$(query: StartDigitalSignatureRequest): Observable<StartDigitalSignatureResponse>{
    return this.post(`startDigitalSignature`, query, {}, true, true);
  }

  signDigitalSignature$(query: SignDigitalSignatureRequest): Observable<SignDigitalSignatureResponse>{
    return this.post(`signDigitalSignature`, query, {}, true, true);
  }

  rejectDigitalSignature$(query: RejectDigitalSignatureRequest): Observable<boolean> {
    return this.post(`rejectDigitalSignature`, query, {}, true, true).pipe(map((res: RejectDigitalSignatureResponse) => res.statusCode === 0));
  }

  //SALVATAGGIO DELL'ADESIONE
  saveAdesione$(query: AdesioneModeRequest): Observable<any> {
    return this.post(`SaveAdesioneFEA`, query, {}, true);
  }

}
