import { environment } from './../../../environments/environment';
import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { DocumentInfo, EndAddSignatureRequest, Signature, Signer, SignInfo } from 'src/app/shared/classes/document';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { AddSignatureDialogComponent } from 'src/app/shared/components/dialog/add-signature-dialog/add-signature-dialog.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { DocumentViewComponent } from '../document-view/document-view.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConfirmSheetsComponent } from 'src/app/shared/components/sheets/confirm-sheets/confirm-sheets.component';
import { DetailSheetComponent } from 'src/app/shared/components/sheets/detail-sheet/detail-sheet.component';
import { ClearOperationComponent } from 'src/app/shared/components/dialog/clear-operation/clear-operation.component';

@Component({
  selector: 'app-document-add-signature',
  templateUrl: './document-add-signature.component.html',
  styleUrls: ['../document-view/document-view.component.scss', './document-add-signature.component.scss']
})
export class DocumentAddSignatureComponent implements OnInit, AfterViewInit {
  
  @ViewChild('sidenav') sidenav : MatSidenav;

  laptopQuery: MediaQueryList;
  mobileQuery: MediaQueryList;
  hideProgressBar: boolean;
  zoom: number;
  fullHeight: boolean;
  showInfo: boolean;
  signatueList: Signature[] = [];
  data: { signers: Signer[]; documentInfos: DocumentInfo[]; };
  toolbarInfo: {document: DocumentInfo; page: number;};
  counter: number;
  
  private adHeight = 8; // questi dati sono gli stessi che il backand mi restituisce quando bisogna firmare un punto firma
  private adWidth = 29;
  private _showSidemenu: boolean;
  private _sidemenuTimeout;
  private _zoomCounter: number;
  private _minZoom: number;
  private _maxZoom: number;

  clearsidemenuTimeout(): void { clearTimeout(this._sidemenuTimeout); this._sidemenuTimeout = null;}

  get zoomCounter(): number { return this._zoomCounter; }
  get showSidemenu(): boolean { return this._showSidemenu; }
  set showSidemenu(val: boolean) {
    if (val && !this._sidemenuTimeout) { this._sidemenuTimeout = setTimeout(() => { this.showSidemenu = false }, 4000); }
    else if (!val) { this.clearsidemenuTimeout(); }
    this._showSidemenu = val;
  }

  constructor(
    private api: ApiHttpService,
    private media: MediaMatcher,
    private dialog: MatDialog,
    private util: UtilService,
    private bottomSheet: MatBottomSheet,
    private router: Router
  ) {
    this.data = { signers: [], documentInfos: []};
    this.toolbarInfo = { document: null, page: 0 };
    this.mobileQuery = media.matchMedia(' (min-width: 320px) and (max-width: 480px)');
    this.laptopQuery = this.media.matchMedia('(min-width: 1025px)');
    this.laptopQuery.addEventListener('change', (e)=> e.matches ? this.sidenav.open() : this.sidenav.close());
    this._showSidemenu = true;
    this.zoom = .9;
    this._zoomCounter = .1;
    this._minZoom = .3;
    this._maxZoom = 1.7;
    this.counter = 0;
  }
  
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    const asid = localStorage.getItem('addSignatureID');
    this.api.startAddSignature$(asid).subscribe(res => {
      if (res.status === 0) {
        if(res.backUrlSignature)localStorage.setItem('backUrlSignature',  res.backUrlSignature);
        this.data.signers = res.signers;
        this.data.documentInfos = res.documentInfos;
        if (this.data.documentInfos.length) {this.toolbarInfo = { document: this.data.documentInfos[0], page: 1}}
        this.hideProgressBar = true;
        this._showSidemenu = false;
        this.showInfo = true;
        setTimeout(() => this.showInfo = false, 4500);
        !this.laptopQuery.matches ? this.sidenav.close(): '';
      } else { localStorage.removeItem('sessionASID'); }
    });
  }

  thumbnailSelect(page, docCounter): void {
    if (!this.laptopQuery.matches) { this.sidenav.close(); }
    this.showSidemenu = true;
    this.toPage(page, docCounter);
  }

  openNoteDialog(): void {
    const dialogRef = this.dialog.open(ClearOperationComponent, {
      disableClose: true,
      width: "500px"
    });
  }

  movetHandle(event): void {
    if(!this.showSidemenu && this.util.moveSpeed(event)) {
      this.showSidemenu = true;
    }
  }

  scrollBack(event): void {
    const dc: any = document.getElementById("documentsContainer");
    const st = event.target.scrollTop;
    // ciclo i documenti (article dentro main)
    for (let i = 0; i < dc.children.length; i++) {
      // arr = numero di pagine all'interno del doc corrente (array di div || figure || img)
      const arr = [...dc.children[i].children];
      // trovo la prima pagina che ha la posizione maggiore all'attuale scrollTop dell'elemento
      const el = arr.findIndex(c => ((c.offsetTop + c.offsetHeight) - c.offsetHeight/4) > st); // TODO da migliorare
      if (el > -1) { // se lo trovo dunque .. altrimenti continuo a cercare
        this.toolbarInfo.page = el + 1; // imposto il numero di pagina corrente(nella view), su toolbarInfo;
        this.toolbarInfo.document = this.data.documentInfos[i]; // e il relativo documento;
        break;
      }
    }
  }

  toolbarPageCounterChange(event, docCounter: number): void {
    const val = event.target.value;
    const doc = this.data.documentInfos.find(el => el.documentCounter === docCounter);
    // event.target.value = val.toString().replace(/^0+/, ''); // TODO rimuovere leading zero da input utente
    event.target.value = val <= 0 || (doc && val > doc.pages)? this.toolbarInfo.page: val;
    this.toolbarInfo.page = event.target.value;
    this.toPage(this.toolbarInfo, docCounter);
  }

  navPages(c: number): void {
    let val =  (c > 0? this.toolbarInfo.page + 1 : this.toolbarInfo.page-1);
    this.toolbarInfo.page = (val > this.toolbarInfo.document.pages && this.toolbarInfo.document.documentCounter <  this.data.documentInfos.length) ? 1 :
                            (val <= 0 && this.toolbarInfo.document.documentCounter > 1) ? this.data.documentInfos[this.toolbarInfo.document.documentCounter-1].pages : 
                            (val <= 0 || val > this.toolbarInfo.document.pages)? this.toolbarInfo.page : val;
    this.toolbarInfo.document = (val > this.toolbarInfo.document.pages && this.toolbarInfo.document.documentCounter <  this.data.documentInfos.length) ? 
                                 this.data.documentInfos[this.toolbarInfo.document.documentCounter] :
                                 (val <= 0 && this.toolbarInfo.document.documentCounter > 1) ? this.data.documentInfos[this.toolbarInfo.document.documentCounter-2] :
                                 this.toolbarInfo.document;  
    this.toPage(this.toolbarInfo.page, this.toolbarInfo.document.documentCounter); 
  }

  heightWidthToggle(): void {
    if (this.fullHeight){
      this.setZoom(1); // setta fullHeight a false e adatta l'immagine in base alla larghezza;
    } else {
      this.fullHeight = true;
      this.zoom= .6;
      setTimeout(()=> this.toPage(this.toolbarInfo.page, this.toolbarInfo.document.documentCounter), 1);
    }
  }

  setZoom(val: number): void {
    this.zoom = val;
    this.zoom = (this.zoom < this._minZoom) ? this._minZoom : (this.zoom > this._maxZoom) ? this._maxZoom : this.zoom;
    this.fullHeight = false; // rimuove full-height class dalle img, priorità su heightWidthToggle;
  }

  mouseEnterInfo(): void {
    if(this.mobileQuery.matches) {
      this.showSidemenu = false;
    } else  {
      this._showSidemenu = true;
      this.clearsidemenuTimeout();
    }
  }

  addSignature(event, docID, page): void {
    const dialogRef = this.dialog.open(AddSignatureDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        signers: this.data.signers,
        required: false
      }
    });
    dialogRef.beforeClosed().subscribe(result => { 
      if(result.res) {
        const child =  event.target;
        const rect = child.getBoundingClientRect();

        let percY = ((event.offsetY > 0 ? event.offsetY : (event.clientY - rect.top)) / child.offsetHeight) * 100;
        let percX = ((event.offsetX > 0 ? event.offsetX : (event.clientX - rect.left)) / child.offsetWidth) * 100;
        percY = percY < (this.adHeight/2) ? (this.adHeight/2) : (percY > 100 - this.adHeight/2) ? 100 - this.adHeight/2 : percY;
        percX = percX < (this.adWidth/2) ? (this.adWidth/2) : (percX > 100 - this.adWidth/2) ? 100 - this.adWidth/2 : percX;
    
        const sign: Signature = {
          required: result.required,
          height: 0,
          width: 0,
          id: this.counter++,
          top: percY,
          left: percX,
          dragPosition: {x: 0, y: 0},
          signer: result.signer,
          documentID: docID,
          page
        }
        this.signatueList.push(sign);
      }
    });
  }

  addSign(s: Signature, parent): void {
    const el = document.getElementById('asid-'+s.id);
    const top = parseFloat(el.style.top) + ((s.dragPosition.y / parent.offsetHeight) * 100);
    const left = parseFloat(el.style.left) + ((s.dragPosition.x / parent.offsetWidth) * 100);
    const newSign: Signature = {
      required: s.required,
      height: 0,
      width: 0,
      id: this.counter++,
      top: top,
      left: left,
      dragPosition: {x: 0, y: 0},
      signer: s.signer,
      documentID: s.documentID,
      page: s.page
    }
    // this.signatueList = this.signatueList.filter(el => el.id !== s.id);
    const ind = this.signatueList.findIndex(el => el.id === s.id);
    this.signatueList.splice(ind, 1);
    this.signatueList.push(newSign);
  }


  dragEnded(event, s: Signature): void {
    s.dragPosition = event.source.getFreeDragPosition() ;
    const signContainer = event.source.getRootElement().offsetParent;//document.getElementById('sign-container');
    this.addSign(s, signContainer);
  }

  deleteSign(s: Signature): void {
    const sheetRef = this.bottomSheet.open(ConfirmSheetsComponent);
    sheetRef.afterDismissed().subscribe(res => {
      if(res){
        this.signatueList = this.signatueList.filter(el => el.id !== s.id);
      }
    });
  }

  edit(s: Signature): void {
    const dialogRef = this.dialog.open(AddSignatureDialogComponent, {
      disableClose: true,
      width: "500px",
      data: {
        signers: this.data.signers,
        required: s.required
      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      if(result.res) {
        s.required = result.required;
        s.signer = result.signer;
      }
    });
  }
  
  details(s: Signature): void {
    if(!this.laptopQuery.matches || this.zoom < .7) {
      const sheetRef = this.bottomSheet.open(DetailSheetComponent, {
        data: { signature: s }
      });
      sheetRef.afterDismissed().subscribe(res => {
        switch(res) {
          case 'delete': this.deleteSign(s); break;
          case 'edit': this.edit(s); break;
        }
      });
    }
  }

  complete(): void {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: "Completa",
        body: "sei sicuro di voler completare la pratica?"
      }
    });
    dialog.beforeClosed().subscribe(result => {
      if (result === true) { 
        const query: EndAddSignatureRequest = {
          folderUUID: localStorage.getItem('addSignatureID'),
          puntiFirma: []
        }
        this.signatueList.forEach(s => {
          const signInfo: SignInfo = {
            cordX: s.left - (this.adWidth/2),
            cordY: (100 - s.top) - (this.adHeight/2),
            firmatario: s.signer.signerID,
            required: s.required,
            documentID: s.documentID,
            pagina: s.page
          }
          query.puntiFirma.push(signInfo);
        });
        this.api.endAddSignature$(query).subscribe(res => {
          if (res.status === 0) {
            if (!localStorage.getItem('backUrlSignature')) localStorage.setItem('backUrlSignature', environment.backUrlAS);
            this.router.navigate(['report','added']);
          }
        });
      }
    });

  }

  private toPage(page, docCounter): void {
    const el = document.getElementById('doc'+docCounter+'page'+page);
    if (el) { el.scrollIntoView(); }
  }

}
