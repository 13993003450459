import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHttpService } from '../core/services/api-http.service';
import { SigningModeRequest } from '../shared/classes/sign';
import { OpenTransactionRequest, TransactionRequest } from '../shared/classes/transaction-pages';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  @ViewChild('fistStep', {static: false}) fsSectionRef: ElementRef;
  @ViewChild('secondStep', {static: false}) ssSectionRef: ElementRef;
  @ViewChild('thirdStep', {static: false}) tsSectionRef: ElementRef;
  
  operationID: string;
  otp: number;
  phone: string;
  status: string;
  errorMessage: string;
  loading: boolean;
  otpDisabled: boolean;
  cd;
  counter: {date: number, m: string; s: string} = {date: 0, m: '05', s: '00'};
  // newOTPDisabled: boolean;

  transactionID: string;

  get fsSection(): any { return this.fsSectionRef.nativeElement; }
  get ssSection(): any { return this.ssSectionRef.nativeElement; }
  get tsSection(): any { return this.tsSectionRef.nativeElement; }

  constructor(
    private api : ApiHttpService,
    private router: Router
  ) { }

  ngOnInit(): void { 
    this.otpDisabled = true;
    this.transactionID = localStorage.getItem('transactionID');
    const data = JSON.parse(localStorage.getItem('userTransactionStatus')); // RICAVO LE INFO DELLO USER, POSSIBILE RIMUOVERLO
    this.phone = data && data.phone && data.phone.replace(/^(.*?).{2}(.{2})$/,'$1XX$2');
  }

  getOTP(): void {
    // OBSOLETO COME CONTROLLO
    if (this.transactionID && !this.loading) {
      this.loading = true;
      this.api.startAuthOperation$(TransactionRequest.make(), true).subscribe(
        data => this.operationID = data.operationID,
        err => this.loading = false, // TODO qualcosa
        () => {
          this.counter.date = new Date().getTime() + 300000; //300000 = 5m
          this.cd = setInterval(() => {
            const now = new Date().getTime();
            const timeLeft = this.counter.date - now;
            let m: string | number = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            let s: string | number = Math.floor((timeLeft % (1000 * 60)) / 1000);
            m = m < 10? `0${m}`: `${m}`; s = s < 10? `0${s}`: `${s}`
            this.counter = {date: this.counter.date, m, s}
            if (timeLeft < 0) {
              clearInterval(this.cd); this.counter = {date: 0, m: '', s: ''}; 
              this.errorMessage = 'Codice scaduto';
              this.next('error');
            }
          }, 1000);
          const otpInput = document.getElementById('otpInput');
          // QUESTO METODO E' USATO ANCHE PER 'RICHIEDERE UNA NUOVA OTP'
          // reiposta lo status a success e muove il div a destra.
          if (this.status === 'error') {
            this.otp = null;
            this.back();
          } else { this.next('success'); }
          setTimeout(() => otpInput.focus(), 500); // "Simpatico"
          this.loading = false;
        }
      );
    }
  }

  autoAuth(): void {
    if (this.otp && (this.otp+'').length >= 6 && !this.loading) {
      const otpInput = document.getElementById('otpInput');
      otpInput.blur();
      this.loading = true;
      const query = OpenTransactionRequest.make(this.operationID, this.otp);
      this.api.openTransaction$(query, true).subscribe(
        data => { 
          this.errorMessage = data.error;
          switch(data.status){
            case -2: this.next('error'); break; // Codice OTP Scaduto
            case -3: // Codice OTP Non Valido
            case -1: this.status = 'error'; break; // TransactionID non presente (non dovrebbe mai succedere)
            case 0 : // Codice OTP valido;
              this.api.tokenFE = data.tokenFE; 
              localStorage.setItem('tokenFE', data.tokenFE); 
              localStorage.setItem('sessionOn', "true");
              // ASYNC
              this.api.getSigningMode$(SigningModeRequest.make()).subscribe(data => localStorage.setItem('signingMode', data.signingMode)); // Chiamarlo dopo aver slavato il tokenFE
              if (localStorage.getItem("transactionIDAdesioneFEA")) {
                this.router.navigate(['adesione']);
              } else {
                this.router.navigate(['document']); 
              }
              break;           
          }
        },
        err => this.loading = false,
        () => { this.loading = false; }
      );
    }
  }

  inputFormat(): void { this.otp = this.otp > 99999 ? Number.parseInt((this.otp/10)+'') : this.otp; }

  next(status: 'success' | 'error' ): void {
    const moveLeft = 'translate(-100%, -50%)';
    const moveFocus = 'translate(0%, -50%)';
    this.status = status;
    if (status === 'success') {
      this.otpDisabled = false;
      this.fsSection.style.opacity = '0';
      this.fsSection.style.transform = moveLeft;
      this.ssSection.style.opacity = '1';
      this.ssSection.style.transform = moveFocus;
    } else if (status === 'error') {
      this.otpDisabled = true;
      this.ssSection.style.opacity = '0';
      this.ssSection.style.transform = moveLeft;
      this.tsSection.style.opacity = '1';
      this.tsSection.style.transform = moveFocus;
    }
  }

  back(): void {
    // ..
    if (this.status === 'error') {
      this.status = 'success';
      this.otpDisabled = false;
      this.tsSection.style.opacity = '0';
      this.tsSection.style.transform = 'translate(100%, -50%)';
      this.ssSection.style.opacity = '1';
      this.ssSection.style.transform = 'translate(0%, -50%)';
    }
  }

}
