export class PageRequest {
    pageNumber: number;
    documentID: number;
    tokenFE: string;
    static make(pageNumber: number, documentID: number): PageRequest {
        return {
            pageNumber,
            documentID,
            tokenFE: localStorage.getItem('tokenFE')
        }
    }
}

export class PageResponse {
    error: string;
    pageBase64: string;
    status: number;
}
