<app-ke-wave [status]="status">
    <div #fistStep class="fadeIn firstStep"> <!-- SOSTITUIRE CON NG_CONTENT -->
        <h1 class="ke-purple-dark">Autenticazione richiesta</h1>
        <div class="mt-2h ke-content ke-grey">
            <p>Il mittente richiede di verificare la propria identità tramite:</p>
            <li class="bold mt-2h">SMS</li>
            <div class="py-2h mt-2h button-load-r">
                <button mat-raised-button [disabled]="!otpDisabled" color="accent" class="bump-button" (click)="getOTP()">RICHIEDI IL CODICE VIA SMS</button>
                <div class="fill d-flex justify-content-center">
                    <mat-spinner *ngIf="loading" diameter="25"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
    <div #secondStep class="secondStep">
        <div class="ke-content ke-grey">
            <div class="stepper">
                <div class="step" [class.fadeLeftOut]="status === 'error'">
                    <h2 class="ke-green">Verifica codice</h2>
                </div>
                <div class="step out" [class.fadeRightIn]="status === 'error'">
                    <h2 class="ke-red">Codice errato</h2>
                </div>
            </div>
            <div class="mt-2h">Un codice di 6 cifre è stato inviato al numero di cellulare <b>{{phone}}</b></div>
            <div class="fs-1r">Riferimento operazione: <b>{{operationID}}</b></div>

            <div class="mt-2h" [ngClass]="{'ke-green': (status==='success'), 'ke-red': (status==='error')}">
                <mat-form-field appearance="outline" [hintLabel]="'Tempo '+(counter.date < 1? 'scaduto.': 'rimasto:')">
                    <mat-label class="c-inherit">Codice OTP</mat-label>
                    <input id="otpInput"
                           #otpInput="ngModel"
                           matInput
                           max="6"
                           [disabled]="otpDisabled"
                           type="number"
                           pattern="\d{6}"
                           placeholder="inviato via sms" 
                           [(ngModel)]="otp"
                           (keypress)="inputFormat()"
                           (keyup)="autoAuth()" 
                           autocomplete="off">
                    <mat-hint *ngIf="counter.date > 0" align="end">{{counter.m +':'+ counter.s}}</mat-hint>
                    <!-- <mat-error *ngIf="otpInput.hasError('pattern')">Codice di 6 cifre</mat-error> -->
                </mat-form-field>
            </div>

            <div class="py-2h f-center">
                <button mat-raised-button 
                        [disabled]="!otp || ((otp+'').length < 6)"
                        [color]="status === 'error' ? 'warn' : 'primary'"
                        (click)="autoAuth()">{{status === 'error' ? 'RIPROVA' : 'AUTENTICATI'}}</button>
                <div class="fill d-flex pl-2r">
                    <mat-spinner class="green" *ngIf="loading" diameter="25"></mat-spinner>
                </div>
            </div>
        </div>
    </div>

    <div #thirdStep class="thirdStep">
        <div class="ke-content ke-grey">
            <h2 class="ke-red">{{errorMessage}}</h2>
            <p class="mt-2h mb-0">Il codice OTP per questa transazione è scaduto.</p>
            <p class="mt-0">Per continuare, richiedi un nuovo codice.</p>

            <div class="py-2h mt-2h button-load-r">
                <button mat-raised-button 
                        color="warn"
                        class="bump-button"
                        (click)="getOTP()"
                        [disabled]="!otpDisabled">RICHIEDI IL CODICE VIA SMS</button>
                <div class="fill d-flex justify-content-center">
                    <mat-spinner class="red" *ngIf="loading" diameter="25"></mat-spinner>
                </div>
            </div>
        </div>
    </div>
</app-ke-wave>