<main class="f-center h-100 flex-column p-relative">
    <img class="abs-logo" src="assets/img/KeFirma_logo_black.svg" alt="">
    <!-- SIMPLE WAVE -->
    <span class="kedos-wave" 
         [ngClass]="{
             'ke-bg-red': (status === 'rejected' || status === 'busy' || status === 'deleted'),
             'ke-bg-green': ((status === 'signed' || status === 'added'))}">
    </span>
    <!-- USER INFO: TODO forse è meglio utilizzare article -->
    <section id="user-info" class="f-center flex-column p-1r text-center ke-purple p-relative"
             [ngClass]="{
                 'ke-red': (status === 'rejected' || status === 'busy' || status === 'deleted'),
                 'ke-green': ((status === 'signed' || status === 'added'))}">
        <mat-icon class="fs-10r bounceIn">
            {{status === 'rejected' ? 'assignment_late' : 
                status === 'signed' ? 'assignment_turned_in' : 
                status === 'deleted' ? 'folder_delete' : 'assignment'}}
        </mat-icon>
        <h1 class="fs-2r mt-2h bold fadeFromBottom">
            <!-- TODO CHIAVI -->
            {{ status === 'rejected' ? 'documento rifiutato.' : 
                status === 'busy' ? 'documento occupato' : 
                status === 'signed' ? 'documento firmato' : 
                status === 'added' ? 'documento modificato' : 
                status === 'deleted' ? 'documento eliminato' : 'nessun documento' }}
        </h1>
        <!-- <img *ngIf="status !== 'deleted'" class="logo fadeIn" src="assets/img/kefirma-logo.png" width="25" alt=""> -->
        <h3 *ngIf="status === 'signed' && email" class="px-3 mt-0">Una copia del documento è stata inviata a {{email}}</h3>
        <h3 *ngIf="status === 'deleted'" class="px-3 mt-0">La pratica è stata eliminata</h3>
        <h3 *ngIf="status === 'busy'" class="px-3 mt-0">In questo momento il documento è utilizzato da un altro utente e non può essere aperto, Ti preghiamo di riprovare tra qualche minuto.</h3>
    </section>
</main>