<div class="h-100 overflow-h bg-mintcream">
    <div class="content-container ke-scroll" [ngClass]="status === 'success'? 'green' : status === 'error' ? 'red': ''">
        <img src="assets/img/kefirma_logo_inline_black.png" class="kefirma-logo-viewport">
        <div class="content">
            <ng-content></ng-content>
        </div>
    </div>
    <div class="moveUp wave-container" [ngClass]="!status? 'show' : 'hide'">
        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
        <div class="wave wave4"></div>
    </div>
    <div class="wave-container" [ngClass]="status === 'success' ? 'show' : 'hide'">
        <div class="wave green wave1"></div>
        <div class="wave green wave2"></div>
        <div class="wave green wave3"></div>
        <div class="wave green wave4"></div>
    </div>
    <div class="wave-container" [ngClass]="status === 'error' ? 'show' : 'hide'">
        <div class="wave red wave1"></div>
        <div class="wave red wave2"></div>
        <div class="wave red wave3"></div>
        <div class="wave red wave4"></div>
    </div>
</div>