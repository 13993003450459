import * as moment from "moment";

export class SignDocumentRequest {
    gpsInfo: string;
    operationID: string;
    otpCode: number;
    signatureFieldID: number;
    tokenFE: string;
    static make(signatureFieldID: number,  operationID: string, otpCode: number, gpsInfo: string): SignDocumentRequest {
        return {
            gpsInfo,
            operationID,
            otpCode,
            signatureFieldID,
            tokenFE: localStorage.getItem('tokenFE')
        }
    }
}

// SigningMode ==============================================================
export enum SigningMode {
    CLICK_SIGN = "ClickSign",
    OTP_SIGN = "OTPSign"
}

export class SigningModeRequest {
    tokenFE: string;
    transactionID: string;
    static make(): SigningModeRequest {
        return {
            tokenFE: localStorage.getItem('tokenFE'),
            transactionID: localStorage.getItem('transactionID')
        }
    }
}

export class AdesioneModeRequest {
    transactionID: string;
    luogoNascita: string;
    dataNascita: string;
    docFrontBase64: string;
    docRearBase64: string;
    static make(luogoNascita: string, dataNascita: string, docFrontBase64: string, docRearBase64: string): AdesioneModeRequest {
        return {
            luogoNascita,
            dataNascita: moment(dataNascita).format("YYYYMMDD"),
            docFrontBase64,
            docRearBase64,
            transactionID: localStorage.getItem('transactionIDAdesioneFEA')
        }
    }
}

export class SigningModeResponse {
    error: string;
    signingMode: string;
    status: number;
}

// SignatureField ==========================================================
export class SignatureField {
    h: number;
    page: number;
    required: boolean;
    signatureFieldID: number;
    w: number;
    x: number;
    y: number;
    signed?: boolean; // custom field
}

export class SignatureFieldsRequest {
    documentID: number;
    tokenFE: string;
    transactionID;
    static make(documentID): SignatureFieldsRequest {
        return {
            documentID,
            tokenFE: localStorage.getItem('tokenFE'),
            transactionID: localStorage.getItem('transactionIDAdesioneFEA') ? localStorage.getItem('transactionIDAdesioneFEA') : localStorage.getItem('transactionID')
        }
    }
}

export class SignatureFieldsResponse {
    error: string;
    signatureFields: SignatureField[];
    status: number;
}

export class SignOperationRequest {
    signatureFieldID: number;
    tokenFE: string;
    static make(signatureFieldID: number): SignOperationRequest {
        return {
            signatureFieldID,
            tokenFE: localStorage.getItem('tokenFE')

        }
    }
}