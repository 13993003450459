import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Signer } from 'src/app/shared/classes/document';

interface DialogData {
  signers: Signer[];
  required: boolean;
}
@Component({
  selector: 'app-add-signature-dialog',
  templateUrl: './add-signature-dialog.component.html',
  styleUrls: ['./add-signature-dialog.component.scss']
})
export class AddSignatureDialogComponent implements OnInit {
  required: boolean;
  signer: Signer;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: DialogData,
    private dialogRef: MatDialogRef<AddSignatureDialogComponent>,
  ) { }
  get data(): DialogData { return this._data; }


  ngOnInit(): void {
    this.required = this.data.required;
    if(this.data.signers && this.data.signers.length > 0 && this.data.signers.length < 2 ) {
      this.signer = this.data.signers[0];
    }
  }
  next(): void {
    this.dialogRef.close({res: true, required: this.required, signer: this.signer});
  }

}
