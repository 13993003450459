import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clear-operation',
  templateUrl: './clear-operation.component.html',
  styleUrls: ['./clear-operation.component.scss']
})
export class ClearOperationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  next(): void {
    location.reload();
  }

}
