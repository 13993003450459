import { ElementRef, Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ke-wave',
  templateUrl: './ke-wave.component.html',
  styleUrls: ['./ke-wave.component.scss']
})
export class KeWaveComponent implements OnInit {

  // @ViewChild('waveContainer') waveContainer: ElementRef;

  // @Input() success: boolean;
  // @Input() error: boolean;
  @Input() status: 'success' | 'error';

  constructor() { }

  ngOnInit(): void {
  }

}
