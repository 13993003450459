<div class="f-center">
    <h3 class="ke-green">Dettaglio firma</h3>
    <div class="fill"></div>
    <button mat-icon-button (click)="edit()"><mat-icon>edit</mat-icon></button>
    <button mat-icon-button (click)="data.signature.required = !data.signature.required" [ngClass]="data.signature.required ? 'ke-red' : ''"><mat-icon>flag</mat-icon></button>
    <button mat-icon-button color="warn" (click)="deleteSign()" class="mr-4"><mat-icon>delete</mat-icon></button>
    <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
</div>
<main>
    <div class="item">
        <div class="profball ke-bg-blue">{{(data.signature.signer? data.signature.signer.name[0]: '') + (data.signature.signer? data.signature.signer.lastname[0]: '')}}</div>
        <div>{{data.signature.signer.name + ' ' + data.signature.signer.lastname}}</div>
    </div>
</main>