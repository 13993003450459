<h2 mat-dialog-title class="ke-red">{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
    <p>{{data.body}}</p>
    <div class="ke-red">
        <mat-form-field appearance="outline" class="ke-red w-100">
            <mat-label>Motivo (opzionale)</mat-label>
            <textarea matInput maxlength="500" rows="7" type="text" [(ngModel)]="value" [disabled]="loading"></textarea>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close [disabled]="loading">Annulla</button>
    <button mat-button color="warn" (click)="next()" cdkFocusInitial>Continua</button>
    <span class="fill"></span>
    <mat-spinner *ngIf="loading" class="red" diameter="25"></mat-spinner>
</mat-dialog-actions>