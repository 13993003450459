// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://kotptest-api.kedos-srl.it/kefirma-otp-ws/rest/OTPRest/',
  apiUrlDigital: 'https://lab.kefirma.it/KeFirmaOrchestrator/api/kefirmadigitale/',
  basicAuth: 'a2VmaXJtYW90cDpRKkBvd15IVnExZ1Q=',
  backUrlAS: 'https://lab.kefirma.it/KeFirmaOrchestrator/secure/saw.jsp' //ADD SIGNATURE
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
